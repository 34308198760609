@import url("https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");
/****** Transition ******/
/****** Shadow ******/
/****** Transform ******/
/****** Radius ******/
/****** Flex ******/
/****** Lineheight ******/
/****** Order ******/
/****** Placeholder ******/
/****** Font Size ******/
/****** Linear Gradient ******/
/****** box shadow ******/
@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?a9ll1c");
  src: url("../fonts/icomoon.eot?a9ll1c#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?a9ll1c") format("truetype"), url("../fonts/icomoon.woff?a9ll1c") format("woff"), url("../fonts/icomoon.svg?a9ll1c#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-email-4:before {
  content: "\e900";
  color: #fff;
}

.icon-illustration:before {
  content: "\e903";
  color: #fff;
}

.icon-data:before {
  content: "\e904";
  color: #575757;
}

.icon-application:before {
  content: "\e905";
  color: #575757;
}

.icon-open-2:before {
  content: "\e91b";
}

.icon-conversation-1:before {
  content: "\e908";
  color: #3454b2;
}

.icon-open-2-1:before {
  content: "\e909";
  color: #3454b2;
}

.icon-map-location-1:before {
  content: "\e906";
  color: #fff;
}

.icon-phone-2-1:before {
  content: "\e907";
  color: #fff;
}

.tab_section h2 {
  font-size: 50px;
  font-size: 3.125rem;
  line-height: 56px;
  line-height: 3.5rem;
  color: #393939;
  font-weight: 700;
  font-family: "PT Sans";
  margin-bottom: 18px;
  letter-spacing: -0.1px;
  margin-bottom: 10px;
  text-align: center;
}
@media (min-width: 1300px) and (max-width: 1450px) {
  .tab_section h2 {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 62px;
    line-height: 3.875rem;
  }
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .tab_section h2 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 45px;
    line-height: 2.8125rem;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .tab_section h2 {
    font-size: 35px;
    font-size: 2.1875rem;
    line-height: 34px;
    line-height: 2.125rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .tab_section h2 {
    font-size: 32px;
    font-size: 2rem;
    line-height: 25px;
    line-height: 1.5625rem;
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .tab_section h2 {
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .tab_section h2 {
    margin-bottom: 0px;
  }
}
.tab_section .container {
  max-width: 1150px;
}
.tab_section .nav {
  border: none;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-bottom: 40px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .tab_section .nav {
    padding-bottom: 28px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .tab_section .nav {
    -ms-flex-pack: distribute;
        justify-content: space-around;
    padding-bottom: 25px;
  }
}
@media (max-width: 767px) {
  .tab_section .nav {
    padding-bottom: 0px;
    padding-top: 15px;
  }
}
.tab_section .nav .nav-item {
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-weight: 400;
  font-family: "PT Sans";
  color: #868686;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .tab_section .nav .nav-item {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .tab_section .nav .nav-item {
    font-size: 17px;
    font-size: 1.0625rem;
    padding-left: 10px;
  }
}
.tab_section .nav .nav-item .nav-link {
  color: #868686;
  background: none;
  border: none;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .tab_section .nav .nav-item .nav-link {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .tab_section .nav .nav-item .nav-link {
    padding: 0;
  }
}
@media (max-width: 767px) {
  .tab_section .nav .nav-item .nav-link {
    font-size: 20px;
    font-size: 1.25rem;
    padding-top: 0;
  }
}
.tab_section .nav .nav-item .nav-link.active {
  color: #fe6c3a;
}

.portfolio_info_sec {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(58%, #fdb913), color-stop(58%, #f7f7f7));
  background: linear-gradient(180deg, #fdb913 58%, #f7f7f7 58%);
  padding-top: 80px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .portfolio_info_sec {
    padding-top: 55px;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(53%, #fdb913), color-stop(53%, #f7f7f7));
    background: linear-gradient(180deg, #fdb913 53%, #f7f7f7 53%);
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .portfolio_info_sec {
    padding-top: 55px;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(54%, #fdb913), color-stop(54%, #f7f7f7));
    background: linear-gradient(180deg, #fdb913 54%, #f7f7f7 54%);
  }
}
@media (max-width: 767px) {
  .portfolio_info_sec {
    background: #fdb913;
    padding: 30px 0;
  }
}
.portfolio_info_sec .container {
  max-width: 1144px;
}
.portfolio_info_sec .content_div_sec {
  margin-top: 12px;
}
.portfolio_info_sec h2 {
  font-size: 50px;
  font-size: 3.125rem;
  line-height: 56px;
  line-height: 3.5rem;
  color: #393939;
  font-weight: 700;
  font-family: "PT Sans";
  margin-bottom: 18px;
  letter-spacing: -0.1px;
  text-align: center;
  margin-bottom: 0px;
  font-size: 55px;
  font-size: 3.4375rem;
  line-height: 60px;
  line-height: 3.75rem;
  color: #ffca49;
  text-transform: uppercase;
  margin-bottom: 20px;
}
@media (min-width: 1300px) and (max-width: 1450px) {
  .portfolio_info_sec h2 {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 62px;
    line-height: 3.875rem;
  }
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .portfolio_info_sec h2 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 45px;
    line-height: 2.8125rem;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .portfolio_info_sec h2 {
    font-size: 35px;
    font-size: 2.1875rem;
    line-height: 34px;
    line-height: 2.125rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .portfolio_info_sec h2 {
    font-size: 32px;
    font-size: 2rem;
    line-height: 25px;
    line-height: 1.5625rem;
    margin-bottom: 10px;
  }
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .portfolio_info_sec h2 {
    font-size: 45px;
    font-size: 2.8125rem;
    line-height: 50px;
    line-height: 3.125rem;
    margin: 0;
    margin-bottom: 20px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .portfolio_info_sec h2 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 45px;
    line-height: 2.8125rem;
    margin: 0;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .portfolio_info_sec h2 {
    font-size: 32px;
    font-size: 2rem;
    line-height: 35px;
    line-height: 2.1875rem;
    margin: 0;
    margin-bottom: 10px;
  }
}
.portfolio_info_sec h4 {
  margin-bottom: 4px;
}
@media (max-width: 767px) {
  .portfolio_info_sec h4 {
    text-align: center !important;
  }
}
.portfolio_info_sec p {
  margin-bottom: 4px;
}
.portfolio_info_sec .content_div_sec img {
  padding-right: 15px;
}
@media (min-width: 768px) and (max-width: 999px) {
  .portfolio_info_sec .content_div_sec .gg1btn {
    margin-top: 10px;
  }
}
@media (max-width: 767px) {
  .portfolio_info_sec .content_div_sec {
    text-align: center;
  }
  .portfolio_info_sec .content_div_sec p {
    line-height: 20px;
    line-height: 1.25rem;
  }
}
.portfolio_info_sec .content_div_sec .bottom_content {
  padding-top: 80px;
}
.portfolio_info_sec .content_div_sec .bottom_content h4 {
  margin-bottom: 12px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .portfolio_info_sec .content_div_sec .bottom_content h4 {
    margin-bottom: 12px;
  }
}
.portfolio_info_sec .content_div_sec .bottom_content p {
  margin-bottom: 22px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .portfolio_info_sec .content_div_sec .bottom_content p {
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .portfolio_info_sec .content_div_sec .bottom_content p {
    margin-bottom: 16px;
  }
}
@media (max-width: 767px) {
  .portfolio_info_sec .content_div_sec .bottom_content p {
    margin-bottom: 12px;
  }
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .portfolio_info_sec .content_div_sec .bottom_content {
    padding-top: 20px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .portfolio_info_sec .content_div_sec .bottom_content {
    padding-top: 30px;
  }
}
@media (max-width: 767px) {
  .portfolio_info_sec .content_div_sec .bottom_content {
    padding-top: 15px;
  }
}
.portfolio_info_sec .content_div_sec .bottom_content .image-logo {
  margin-top: 8px;
}

.development_info_sec h2 {
  font-size: 50px;
  font-size: 3.125rem;
  line-height: 56px;
  line-height: 3.5rem;
  color: #393939;
  font-weight: 700;
  font-family: "PT Sans";
  margin-bottom: 18px;
  letter-spacing: -0.1px;
  text-align: center;
  margin-bottom: 40px;
}
@media (min-width: 1300px) and (max-width: 1450px) {
  .development_info_sec h2 {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 62px;
    line-height: 3.875rem;
  }
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .development_info_sec h2 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 45px;
    line-height: 2.8125rem;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .development_info_sec h2 {
    font-size: 35px;
    font-size: 2.1875rem;
    line-height: 34px;
    line-height: 2.125rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .development_info_sec h2 {
    font-size: 32px;
    font-size: 2rem;
    line-height: 25px;
    line-height: 1.5625rem;
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .development_info_sec h2 {
    line-height: 30px;
    line-height: 1.875rem;
  }
}
.development_info_sec .container-fluid {
  padding: 0px;
}
@media (max-width: 767px) {
  .development_info_sec .container-fluid {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
.development_info_sec .development_img_sec {
  padding: 0px;
}
.development_info_sec .development_img_sec img {
  width: 100%;
}
.development_info_sec .development_content_sec {
  padding-bottom: 25px;
}
.development_info_sec .development_content_sec .accordion-item {
  border: none;
  margin-top: 10px;
  border-radius: 12px;
  background: #3454b2;
  color: #ffffff;
}
.development_info_sec .development_content_sec .accordion-item .accordion-header {
  margin: 0;
  position: relative;
}
.development_info_sec .development_content_sec .accordion-item .accordion-header .accordion-button {
  background: #ffffff;
  color: #393939;
  cursor: pointer;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #d7d7d7;
}
.development_info_sec .development_content_sec .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  background: #3454b2;
  color: #ffffff;
  border: none;
  border: 1px solid #3454b2;
}
.development_info_sec .development_content_sec .accordion-item .accordion-header .accordion-button::after {
  content: "+";
  position: absolute;
  right: 12px;
  top: 4px;
  font-size: 27px;
  font-size: 1.6875rem;
  background-image: none;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .development_info_sec .development_content_sec .accordion-item .accordion-header .accordion-button::after {
    top: 12px;
    font-size: 22px;
    font-size: 1.375rem;
    right: 7px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .development_info_sec .development_content_sec .accordion-item .accordion-header .accordion-button::after {
    top: 8px;
    font-size: 22px;
    font-size: 1.375rem;
    right: 7px;
  }
}
@media (max-width: 767px) {
  .development_info_sec .development_content_sec .accordion-item .accordion-header .accordion-button::after {
    top: 9px;
    font-size: 22px;
    font-size: 1.375rem;
    right: 7px;
  }
}
.development_info_sec .development_content_sec .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  content: "-";
  top: 4px;
  font-size: 27px;
  font-size: 1.6875rem;
  background-image: none;
  -webkit-transform: none;
          transform: none;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .development_info_sec .development_content_sec .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
    top: 12px;
    right: 7px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .development_info_sec .development_content_sec .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
    top: 8px;
    right: 7px;
  }
}
@media (max-width: 767px) {
  .development_info_sec .development_content_sec .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
    top: 9px;
    right: 7px;
  }
}
.development_info_sec .development_content_sec .accordion-item .accordion-body {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 25px;
  line-height: 1.5625rem;
  font-weight: 400;
  font-family: "Sarabun";
  color: #525252;
  letter-spacing: -0.1px;
  padding-right: 50px;
  color: #ffffff;
  padding-top: 0;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .development_info_sec .development_content_sec .accordion-item .accordion-body {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 23px;
    line-height: 1.4375rem;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .development_info_sec .development_content_sec .accordion-item .accordion-body {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 22px;
    line-height: 1.375rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .development_info_sec .development_content_sec .accordion-item .accordion-body {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 20px;
    line-height: 1.25rem;
    margin-bottom: 12px;
  }
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .development_info_sec .development_content_sec .accordion-item .accordion-body {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .development_info_sec .development_content_sec .accordion-item .accordion-body {
    padding-right: 20px;
    font-size: 16px;
    font-size: 1rem;
    margin-bottom: 0px;
  }
}
@media (max-width: 767px) {
  .development_info_sec .development_content_sec .accordion-item .accordion-body {
    padding-right: 15px;
    margin-bottom: 0px;
    font-size: 13px;
    font-size: 0.8125rem;
  }
}
.development_info_sec .development_content_sec button {
  font-weight: 500;
  font-family: "Sarabun";
  font-size: 20px;
  font-size: 1.25rem;
  border-radius: 10px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 26px;
  line-height: 1.625rem;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .development_info_sec .development_content_sec button {
    padding-right: 40px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 22px;
    font-size: 1.375rem;
    line-height: 28px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .development_info_sec .development_content_sec button {
    font-size: 18px;
    font-size: 1.125rem;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 40px;
    line-height: 26px;
    line-height: 1.625rem;
  }
}
@media (max-width: 767px) {
  .development_info_sec .development_content_sec button {
    font-size: 16px;
    font-size: 1rem;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 38px;
    padding-left: 13px;
    line-height: 22px;
  }
}
.development_info_sec .development_content_sec button:focus {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  background: none;
}
.development_info_sec .development_content_sec .accordion {
  max-width: 62%;
  margin: 0 auto;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .development_info_sec .development_content_sec .accordion {
    max-width: 100%;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .development_info_sec .development_content_sec .accordion {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  .development_info_sec .development_content_sec .accordion {
    max-width: 100%;
  }
}

.project_discuss_info_sec {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(80%, #ededed), color-stop(50%, #f7f7f7));
  background: linear-gradient(180deg, #ededed 80%, #f7f7f7 50%);
  text-align: center;
  padding-top: 100px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .project_discuss_info_sec {
    padding-top: 80px;
    margin-top: 55px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .project_discuss_info_sec {
    padding-top: 60px;
    margin-top: 40px;
  }
}
@media (max-width: 767px) {
  .project_discuss_info_sec {
    padding-top: 40px;
  }
}
.project_discuss_info_sec strong {
  font-size: 40px;
  font-size: 2.5rem;
  line-height: 50px;
  line-height: 3.125rem;
  color: #393939;
  font-weight: 400;
  font-family: "PT Sans";
  text-align: center;
  display: block;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .project_discuss_info_sec strong {
    font-size: 35px;
    font-size: 2.1875rem;
    line-height: 42px;
    line-height: 2.625rem;
  }
  .project_discuss_info_sec strong br {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .project_discuss_info_sec strong {
    font-size: 32px;
    font-size: 2rem;
    line-height: 30px;
    line-height: 1.875rem;
    margin-bottom: 5px;
  }
  .project_discuss_info_sec strong br {
    display: none;
  }
}
@media (max-width: 767px) {
  .project_discuss_info_sec strong {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 22px;
    line-height: 1.375rem;
    margin-bottom: 8px;
    margin-top: 0px;
  }
  .project_discuss_info_sec strong br {
    display: none;
  }
}
.project_discuss_info_sec h3 {
  font-size: 50px;
  font-size: 3.125rem;
  line-height: 50px;
  line-height: 3.125rem;
  color: #393939;
  font-weight: 700;
  font-family: "PT Sans";
  margin-bottom: 22px;
  text-align: center;
  display: block;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .project_discuss_info_sec h3 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 42px;
    line-height: 2.625rem;
    margin-bottom: 15px;
  }
  .project_discuss_info_sec h3 br {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .project_discuss_info_sec h3 {
    font-size: 32px;
    font-size: 2rem;
    line-height: 30px;
    line-height: 1.875rem;
    margin-bottom: 12px;
  }
  .project_discuss_info_sec h3 br {
    display: none;
  }
}
@media (max-width: 767px) {
  .project_discuss_info_sec h3 {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 26px;
    line-height: 1.625rem;
    margin-bottom: 8px;
    margin-top: 0px;
  }
  .project_discuss_info_sec h3 br {
    display: none;
  }
}
.project_discuss_info_sec .gg1btn {
  color: #ffffff;
  background: #3454b2;
  font-family: "Sarabun";
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  border-radius: 5px;
  padding: 0 55px;
  display: inline-block;
  line-height: 60px;
  line-height: 3.75rem;
  border: 1px solid transparent;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-top: 12px;
  transition: all 500ms ease-in-out;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  position: relative;
  margin-top: 25px;
  cursor: pointer;
}
.project_discuss_info_sec .gg1btn:hover {
  color: #ffffff;
  background: #fe5e20;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .project_discuss_info_sec .gg1btn {
    font-size: 16px;
    font-size: 1rem;
    line-height: 50px;
    line-height: 3.125rem;
    padding: 0 45px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .project_discuss_info_sec .gg1btn {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 43px;
    line-height: 2.6875rem;
    padding: 0 38px;
  }
}
@media (max-width: 767px) {
  .project_discuss_info_sec .gg1btn {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 43px;
    line-height: 2.6875rem;
    padding: 0 37px;
  }
}
.project_discuss_info_sec p {
  text-align: center;
  margin-bottom: 0px;
}
@media (min-width: 768px) and (max-width: 999px) {
  .project_discuss_info_sec p {
    margin: 0;
  }
}
.project_discuss_info_sec p a {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 25px;
  line-height: 1.5625rem;
  font-weight: 400;
  font-family: "Sarabun";
  color: #525252;
  letter-spacing: -0.1px;
  color: #fe5e20;
  font-weight: 700;
  font-size: 30px;
  font-size: 1.875rem;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .project_discuss_info_sec p a {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 23px;
    line-height: 1.4375rem;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .project_discuss_info_sec p a {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 22px;
    line-height: 1.375rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .project_discuss_info_sec p a {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 20px;
    line-height: 1.25rem;
    margin-bottom: 12px;
  }
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .project_discuss_info_sec p a {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .project_discuss_info_sec p a {
    font-size: 22px;
    font-size: 1.375rem;
  }
}
@media (max-width: 767px) {
  .project_discuss_info_sec p a {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
.project_discuss_info_sec .video_section {
  margin-top: 80px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .project_discuss_info_sec .video_section {
    margin-top: 40px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .project_discuss_info_sec .video_section {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .project_discuss_info_sec .video_section {
    margin-top: 20px;
  }
}
.project_discuss_info_sec .video_section iframe {
  border-radius: 20px;
  max-width: 100%;
}
@media (max-width: 767px) {
  .project_discuss_info_sec .video_section iframe {
    max-width: 100%;
    height: 380px;
  }
}

.testimonial_sec h2 {
  font-size: 50px;
  font-size: 3.125rem;
  line-height: 56px;
  line-height: 3.5rem;
  color: #393939;
  font-weight: 700;
  font-family: "PT Sans";
  margin-bottom: 18px;
  letter-spacing: -0.1px;
  text-align: center;
  margin-bottom: 35px;
}
@media (min-width: 1300px) and (max-width: 1450px) {
  .testimonial_sec h2 {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 62px;
    line-height: 3.875rem;
  }
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .testimonial_sec h2 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 45px;
    line-height: 2.8125rem;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .testimonial_sec h2 {
    font-size: 35px;
    font-size: 2.1875rem;
    line-height: 34px;
    line-height: 2.125rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .testimonial_sec h2 {
    font-size: 32px;
    font-size: 2rem;
    line-height: 25px;
    line-height: 1.5625rem;
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .testimonial_sec h2 {
    margin-bottom: 24px;
  }
}
.testimonial_sec .card {
  border: 0;
  border-radius: 15px;
  min-height: 350px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .testimonial_sec .card {
    min-height: 400px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .testimonial_sec .card {
    min-height: 400px;
  }
}
@media (max-width: 767px) {
  .testimonial_sec .card {
    margin-top: 15px;
    min-height: 1px;
  }
}
.testimonial_sec .card .card-body {
  padding: 38px 30px 30px 30px;
  position: relative;
}
@media (min-width: 768px) and (max-width: 999px) {
  .testimonial_sec .card .card-body {
    padding: 30px 18px 30px 18px;
  }
}
@media (max-width: 767px) {
  .testimonial_sec .card .card-body {
    padding: 38px 20px 30px 20px;
  }
}
.testimonial_sec .card .card-body .star_sec {
  margin-bottom: 15px;
}
.testimonial_sec .card .card-body p {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 25px;
  line-height: 1.5625rem;
  font-weight: 400;
  font-family: "Sarabun";
  color: #525252;
  letter-spacing: -0.1px;
  margin-bottom: 22px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .testimonial_sec .card .card-body p {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 23px;
    line-height: 1.4375rem;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .testimonial_sec .card .card-body p {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 22px;
    line-height: 1.375rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .testimonial_sec .card .card-body p {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 20px;
    line-height: 1.25rem;
    margin-bottom: 12px;
  }
}
@media (max-width: 767px) {
  .testimonial_sec .card .card-body p {
    line-height: 18px;
    line-height: 1.125rem;
  }
}
.testimonial_sec .card .card-body ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 0;
  position: absolute;
  bottom: 30px;
}
@media (max-width: 767px) {
  .testimonial_sec .card .card-body ul {
    position: unset;
  }
}
.testimonial_sec .card .card-body ul li {
  list-style-type: none;
}
@media (min-width: 768px) and (max-width: 999px) {
  .testimonial_sec .card .card-body ul li img {
    max-width: 50px;
  }
}
.testimonial_sec .card .card-body ul li strong {
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 21px;
  line-height: 1.3125rem;
  font-weight: 700;
  font-family: "Sarabun";
  color: #0048b6;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .testimonial_sec .card .card-body ul li strong {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .testimonial_sec .card .card-body ul li strong {
    font-size: 15px;
    font-size: 0.9375rem;
  }
}
@media (max-width: 767px) {
  .testimonial_sec .card .card-body ul li strong {
    font-size: 16px;
    font-size: 1rem;
    line-height: 18px;
    line-height: 1.125rem;
  }
}
.testimonial_sec .card .card-body ul li p {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 25px;
  line-height: 1.5625rem;
  font-weight: 400;
  font-family: "Sarabun";
  color: #525252;
  letter-spacing: -0.1px;
  margin-bottom: 0;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .testimonial_sec .card .card-body ul li p {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 23px;
    line-height: 1.4375rem;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .testimonial_sec .card .card-body ul li p {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 22px;
    line-height: 1.375rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .testimonial_sec .card .card-body ul li p {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 20px;
    line-height: 1.25rem;
    margin-bottom: 12px;
  }
}

.work_logo_sec {
  background: #ededed;
}
.work_logo_sec .work_text_wrap {
  padding: 100px 0 80px 0;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .work_logo_sec .work_text_wrap {
    padding: 65px 0 70px 0;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .work_logo_sec .work_text_wrap {
    padding: 60px 0 60px 0;
  }
}
@media (max-width: 767px) {
  .work_logo_sec .work_text_wrap {
    padding: 50px 0 40px 0;
  }
}
.work_logo_sec .work_text_wrap h2 {
  font-size: 50px;
  font-size: 3.125rem;
  line-height: 56px;
  line-height: 3.5rem;
  color: #393939;
  font-weight: 700;
  font-family: "PT Sans";
  margin-bottom: 18px;
  letter-spacing: -0.1px;
  text-align: center;
}
@media (min-width: 1300px) and (max-width: 1450px) {
  .work_logo_sec .work_text_wrap h2 {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 62px;
    line-height: 3.875rem;
  }
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .work_logo_sec .work_text_wrap h2 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 45px;
    line-height: 2.8125rem;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .work_logo_sec .work_text_wrap h2 {
    font-size: 35px;
    font-size: 2.1875rem;
    line-height: 34px;
    line-height: 2.125rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .work_logo_sec .work_text_wrap h2 {
    font-size: 32px;
    font-size: 2rem;
    line-height: 25px;
    line-height: 1.5625rem;
    margin-bottom: 10px;
  }
}
.work_logo_sec .work_text_wrap p {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 25px;
  line-height: 1.5625rem;
  font-weight: 400;
  font-family: "Sarabun";
  color: #525252;
  letter-spacing: -0.1px;
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 28px;
  line-height: 1.75rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 35px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .work_logo_sec .work_text_wrap p {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 23px;
    line-height: 1.4375rem;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .work_logo_sec .work_text_wrap p {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 22px;
    line-height: 1.375rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .work_logo_sec .work_text_wrap p {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 20px;
    line-height: 1.25rem;
    margin-bottom: 12px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .work_logo_sec .work_text_wrap p {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media (max-width: 767px) {
  .work_logo_sec .work_text_wrap p br {
    display: none;
  }
}
.work_logo_sec .work_text_wrap .regular {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.work_logo_sec .work_text_wrap .regular img {
  border-radius: 10px;
  transition: all 500ms ease-in-out;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
}
.work_logo_sec .work_text_wrap .regular img:hover {
  opacity: 0.8;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .work_logo_sec .work_text_wrap .regular > div {
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .work_logo_sec .work_text_wrap .regular > div {
    margin-bottom: 15px;
  }
}
.work_logo_sec .work_text_wrap ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .work_logo_sec .work_text_wrap ul {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
  }
}
.work_logo_sec .work_text_wrap ul li {
  list-style: none;
}
.work_logo_sec .work_text_wrap ul li img {
  border-radius: 10px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .work_logo_sec .work_text_wrap ul li {
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .work_logo_sec .work_text_wrap ul li {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .work_logo_sec .work_text_wrap ul li {
    margin-top: 5px;
  }
}
.work_logo_sec .work_text_wrap .slick-slider .slick-list .slick-track .slick-slide > div {
  padding: 0 10px;
}
.work_logo_sec .work_text_wrap .slick-slider .slick-list .slick-track .slick-slide img {
  width: 100%;
  border-radius: 10px;
}
.work_logo_sec .work_text_wrap .slick-slider .slick-dots {
  position: static;
  margin-top: 20px;
}
@media (max-width: 767px) {
  .work_logo_sec .work_text_wrap .slick-slider .slick-dots {
    margin-top: 0;
  }
}
.work_logo_sec .work_text_wrap .slick-slider .slick-dots li {
  margin-top: 20px;
}
.work_logo_sec .work_text_wrap .slick-slider .slick-dots li button {
  display: block;
  width: 14px;
  height: 14px;
  padding: 0;
  border: none;
  border-radius: 100%;
  background-color: #fe5e20;
  text-indent: -9999px;
}
.work_logo_sec .work_text_wrap .slick-slider .slick-dots li.slick-active button {
  background-color: #3454b2;
}

.blog_info_sec h2 {
  font-size: 50px;
  font-size: 3.125rem;
  line-height: 56px;
  line-height: 3.5rem;
  color: #393939;
  font-weight: 700;
  font-family: "PT Sans";
  margin-bottom: 18px;
  letter-spacing: -0.1px;
  text-align: center;
  margin-bottom: 35px;
}
@media (min-width: 1300px) and (max-width: 1450px) {
  .blog_info_sec h2 {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 62px;
    line-height: 3.875rem;
  }
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .blog_info_sec h2 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 45px;
    line-height: 2.8125rem;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .blog_info_sec h2 {
    font-size: 35px;
    font-size: 2.1875rem;
    line-height: 34px;
    line-height: 2.125rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .blog_info_sec h2 {
    font-size: 32px;
    font-size: 2rem;
    line-height: 25px;
    line-height: 1.5625rem;
    margin-bottom: 10px;
  }
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .blog_info_sec h2 {
    margin-bottom: 28px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .blog_info_sec h2 {
    margin-bottom: 24px;
  }
}
@media (max-width: 767px) {
  .blog_info_sec h2 {
    margin-bottom: 15px;
  }
}
.blog_info_sec .card {
  background: none;
  border: none;
  position: relative;
}
@media (max-width: 767px) {
  .blog_info_sec .card {
    margin-bottom: 20px;
  }
}
.blog_info_sec .card .publishDate {
  position: absolute;
  top: 16px;
  left: 16px;
  background: #fdb913;
  color: #ffffff;
  border-radius: 5px;
}
@media (max-width: 767px) {
  .blog_info_sec .card .publishDate {
    top: 12px;
    left: 12px;
  }
}
.blog_info_sec .card .publishDate h5 {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 25px;
  line-height: 1.5625rem;
  font-weight: 500;
  font-family: "Sarabun";
  padding: 10px 16px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .blog_info_sec .card .publishDate h5 {
    padding: 7px 14px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .blog_info_sec .card .publishDate h5 {
    padding: 4px 10px;
  }
}
@media (max-width: 767px) {
  .blog_info_sec .card .publishDate h5 {
    font-size: 13px;
    font-size: 0.8125rem;
    padding: 8px 10px;
  }
}
.blog_info_sec .card img {
  width: 100%;
  border-radius: 15px;
}
.blog_info_sec .card .card-body {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  padding-top: 28px;
}
@media (min-width: 768px) and (max-width: 999px) {
  .blog_info_sec .card .card-body {
    padding-top: 22px;
  }
}
@media (max-width: 767px) {
  .blog_info_sec .card .card-body {
    padding-top: 15px;
  }
}
.blog_info_sec .card .card-body h4 a {
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 28px;
  line-height: 1.75rem;
  font-weight: 600;
  font-family: "Sarabun";
  color: #000000;
  transition: all 500ms ease-in-out;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .blog_info_sec .card .card-body h4 a {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 24px;
    line-height: 1.5rem;
  }
  .blog_info_sec .card .card-body h4 a br {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .blog_info_sec .card .card-body h4 a {
    font-size: 16px;
    font-size: 1rem;
    line-height: 21px;
    line-height: 1.3125rem;
  }
  .blog_info_sec .card .card-body h4 a br {
    display: none;
  }
}
@media (max-width: 767px) {
  .blog_info_sec .card .card-body h4 a {
    font-size: 16px;
    font-size: 1rem;
    line-height: 22px;
    line-height: 1.375rem;
  }
  .blog_info_sec .card .card-body h4 a br {
    display: none;
  }
}
.blog_info_sec .card .card-body h4 a:hover {
  color: #fe6c3a;
}
.blog_info_sec .blog_info_bun {
  text-align: center;
  margin-top: 30px;
}
@media (max-width: 767px) {
  .blog_info_sec .blog_info_bun {
    margin-top: 0px;
  }
  .blog_info_sec .blog_info_bun .gg1btn {
    margin-top: 0px;
  }
}

.margin-t-b {
  margin: 100px 0;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .margin-t-b {
    margin: 90px 0;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .margin-t-b {
    margin: 60px 0;
  }
}
@media (max-width: 767px) {
  .margin-t-b {
    margin: 40px 0;
  }
}

.margin-t {
  margin-top: 100px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .margin-t {
    margin-top: 90px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .margin-t {
    margin-top: 70px;
  }
}
@media (max-width: 767px) {
  .margin-t {
    margin-top: 40px;
  }
}

.margin-b {
  margin-top: 100px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .margin-b {
    margin-top: 90px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .margin-b {
    margin-bottom: 70px;
  }
}
@media (max-width: 767px) {
  .margin-b {
    margin-bottom: 40px;
  }
}

@media (max-width: 767px) {
  .padding_left_right {
    padding-left: 0px;
    padding-right: 0px;
  }
}

body {
  background: #f7f7f7 !important;
}

.padding_top_bottom {
  padding: 100px 0;
}

.background_color {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #fdb913), color-stop(50%, #f7f7f7));
  background: linear-gradient(180deg, #fdb913 50%, #f7f7f7 50%);
  padding: 60px 0;
}

.two_colom_img_textSec .img_div_sec img {
  max-width: 100%;
  height: auto;
  border-radius: 20px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .two_colom_img_textSec .img_div_sec img {
    border-radius: 17px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .two_colom_img_textSec .img_div_sec img {
    border-radius: 15px;
  }
}
@media (max-width: 767px) {
  .two_colom_img_textSec .img_div_sec img {
    border-radius: 10px;
  }
}
.two_colom_img_textSec .content_div_sec {
  padding-right: 15px;
}
@media (max-width: 767px) {
  .two_colom_img_textSec .content_div_sec {
    padding-right: 0px;
  }
}
.two_colom_img_textSec .content_div_sec h2 {
  font-size: 50px;
  font-size: 3.125rem;
  line-height: 56px;
  line-height: 3.5rem;
  color: #393939;
  font-weight: 700;
  font-family: "PT Sans";
  margin-bottom: 18px;
  letter-spacing: -0.1px;
}
@media (min-width: 1300px) and (max-width: 1450px) {
  .two_colom_img_textSec .content_div_sec h2 {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 62px;
    line-height: 3.875rem;
  }
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .two_colom_img_textSec .content_div_sec h2 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 45px;
    line-height: 2.8125rem;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .two_colom_img_textSec .content_div_sec h2 {
    font-size: 35px;
    font-size: 2.1875rem;
    line-height: 34px;
    line-height: 2.125rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .two_colom_img_textSec .content_div_sec h2 {
    font-size: 32px;
    font-size: 2rem;
    line-height: 25px;
    line-height: 1.5625rem;
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .two_colom_img_textSec .content_div_sec h2 br {
    display: none;
  }
}
@media (max-width: 767px) {
  .two_colom_img_textSec .content_div_sec h2 {
    margin-top: 20px;
  }
  .two_colom_img_textSec .content_div_sec h2 br {
    display: none;
  }
}
.two_colom_img_textSec .content_div_sec p {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 25px;
  line-height: 1.5625rem;
  font-weight: 400;
  font-family: "Sarabun";
  color: #525252;
  letter-spacing: -0.1px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .two_colom_img_textSec .content_div_sec p {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 23px;
    line-height: 1.4375rem;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .two_colom_img_textSec .content_div_sec p {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 22px;
    line-height: 1.375rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .two_colom_img_textSec .content_div_sec p {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 20px;
    line-height: 1.25rem;
    margin-bottom: 12px;
  }
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .two_colom_img_textSec .content_div_sec p {
    margin-bottom: 12px;
  }
  .two_colom_img_textSec .content_div_sec p br {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .two_colom_img_textSec .content_div_sec p {
    margin-bottom: 10px;
  }
  .two_colom_img_textSec .content_div_sec p br {
    display: none;
  }
}
@media (max-width: 767px) {
  .two_colom_img_textSec .content_div_sec p {
    margin-bottom: 10px;
  }
  .two_colom_img_textSec .content_div_sec p br {
    display: none;
  }
}
.two_colom_img_textSec .content_div_sec h3 {
  font-size: 50px;
  font-size: 3.125rem;
  line-height: 50px;
  line-height: 3.125rem;
  color: #393939;
  font-weight: 700;
  font-family: "PT Sans";
  margin-bottom: 22px;
  text-align: left;
  letter-spacing: -0.1px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .two_colom_img_textSec .content_div_sec h3 {
    font-size: 38px;
    font-size: 2.375rem;
    line-height: 40px;
    line-height: 2.5rem;
    margin-bottom: 15px;
  }
  .two_colom_img_textSec .content_div_sec h3 br {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .two_colom_img_textSec .content_div_sec h3 {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 32px;
    line-height: 2rem;
    margin-bottom: 12px;
  }
  .two_colom_img_textSec .content_div_sec h3 br {
    display: none;
  }
}
@media (max-width: 767px) {
  .two_colom_img_textSec .content_div_sec h3 {
    font-size: 25px;
    font-size: 1.5625rem;
    line-height: 26px;
    line-height: 1.625rem;
    margin-bottom: 8px;
    margin-top: 15px;
  }
  .two_colom_img_textSec .content_div_sec h3 br {
    display: none;
  }
}
.two_colom_img_textSec .content_div_sec h3 a {
  color: #3454b2;
}
.two_colom_img_textSec .content_div_sec h4 {
  font-size: 40px;
  font-size: 2.5rem;
  line-height: 47px;
  line-height: 2.9375rem;
  color: #393939;
  font-weight: 700;
  font-family: "PT Sans";
  margin-bottom: 15px;
  text-align: left;
  letter-spacing: -0.1px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .two_colom_img_textSec .content_div_sec h4 {
    font-size: 36px;
    font-size: 2.25rem;
    line-height: 42px;
    line-height: 2.625rem;
    margin-bottom: 8px;
  }
  .two_colom_img_textSec .content_div_sec h4 br {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .two_colom_img_textSec .content_div_sec h4 {
    font-size: 29px;
    font-size: 1.8125rem;
    line-height: 32px;
    line-height: 2rem;
    margin-bottom: 12px;
  }
  .two_colom_img_textSec .content_div_sec h4 br {
    display: none;
  }
}
@media (max-width: 767px) {
  .two_colom_img_textSec .content_div_sec h4 {
    font-size: 27px;
    font-size: 1.6875rem;
    line-height: 28px;
    line-height: 1.75rem;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  .two_colom_img_textSec .content_div_sec h4 br {
    display: none;
  }
}
.two_colom_img_textSec .content_div_sec strong {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 18px;
  line-height: 1.125rem;
  font-weight: 400;
  font-family: "Sarabun";
  display: block;
  letter-spacing: -0.1px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .two_colom_img_textSec .content_div_sec strong {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 22px;
    line-height: 1.375rem;
    margin-bottom: 5px;
    display: block;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .two_colom_img_textSec .content_div_sec strong {
    font-size: 22px;
    font-size: 1.375rem;
    line-height: 19px;
    line-height: 1.1875rem;
    display: block;
    margin-bottom: 0px;
  }
}
@media (max-width: 767px) {
  .two_colom_img_textSec .content_div_sec strong {
    font-size: 21px;
    font-size: 1.3125rem;
    line-height: 12px;
    line-height: 0.75rem;
    margin-top: 20px;
  }
}
.two_colom_img_textSec .content_div_sec h6 {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 26px;
  line-height: 1.625rem;
  font-weight: 700;
  font-family: "Sarabun";
  color: #525252;
  letter-spacing: -0.1px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .two_colom_img_textSec .content_div_sec h6 {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 22px;
    line-height: 1.375rem;
    margin-bottom: 5px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .two_colom_img_textSec .content_div_sec h6 {
    font-size: 16px;
    font-size: 1rem;
    line-height: 22px;
    line-height: 1.375rem;
    margin-bottom: 0px;
  }
}
@media (max-width: 767px) {
  .two_colom_img_textSec .content_div_sec h6 {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 22px;
    line-height: 1.375rem;
  }
}
@media (max-width: 767px) {
  .two_colom_img_textSec .content_div_sec ul {
    margin-bottom: 25px;
  }
}
.two_colom_img_textSec .content_div_sec ul li {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 28px;
  line-height: 1.75rem;
  font-weight: 500;
  font-family: "Sarabun";
  position: relative;
  padding-left: 30px;
  list-style-type: none;
  padding-top: 5px;
}
.two_colom_img_textSec .content_div_sec ul li::before {
  content: "\f35a";
  position: absolute;
  font-family: "Font Awesome 5 free";
  top: 6px;
  left: 0;
}
@media (min-width: 768px) and (max-width: 999px) {
  .two_colom_img_textSec .content_div_sec ul li {
    font-size: 16px;
    font-size: 1rem;
    line-height: 22px;
    line-height: 1.375rem;
    padding-left: 25px;
  }
  .two_colom_img_textSec .content_div_sec ul li::before {
    top: 7px;
  }
}
@media (max-width: 767px) {
  .two_colom_img_textSec .content_div_sec ul li {
    font-size: 16px;
    font-size: 1rem;
    line-height: 22px;
    line-height: 1.375rem;
    padding-left: 25px;
  }
  .two_colom_img_textSec .content_div_sec ul li::before {
    top: 8px;
    left: 0;
  }
}
.two_colom_img_textSec .content_div_sec ol {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 25px 0;
  list-style: none;
}
@media (min-width: 768px) and (max-width: 999px) {
  .two_colom_img_textSec .content_div_sec ol {
    margin-bottom: 10px;
    margin-top: 5px;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
@media (max-width: 767px) {
  .two_colom_img_textSec .content_div_sec ol {
    display: block;
    margin-bottom: 10px;
    margin-top: 15px;
  }
}
.two_colom_img_textSec .content_div_sec ol li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-right: 20px;
}
@media (min-width: 768px) and (max-width: 999px) {
  .two_colom_img_textSec .content_div_sec ol li {
    padding-left: 10px;
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .two_colom_img_textSec .content_div_sec ol li {
    padding-left: 0;
    padding-bottom: 10px;
    display: block;
  }
}
.two_colom_img_textSec .content_div_sec ol li span a {
  font-size: 26px;
  font-size: 1.625rem;
  line-height: 27px;
  line-height: 1.6875rem;
  font-weight: 500;
  font-family: "Sarabun";
  color: #3454b2;
  display: block;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .two_colom_img_textSec .content_div_sec ol li span a {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .two_colom_img_textSec .content_div_sec ol li span a {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 24px;
    line-height: 1.5rem;
  }
}
@media (max-width: 767px) {
  .two_colom_img_textSec .content_div_sec ol li span a {
    font-size: 17px;
    font-size: 1.0625rem;
  }
}
.two_colom_img_textSec .content_div_sec ol li p {
  font-weight: 500;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 25px;
  line-height: 1.5625rem;
  font-weight: 400;
  font-family: "Sarabun";
  color: #525252;
  letter-spacing: -0.1px;
  margin: 0;
  color: #3454b2;
  font-size: 20px;
  font-size: 1.25rem;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .two_colom_img_textSec .content_div_sec ol li p {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 23px;
    line-height: 1.4375rem;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .two_colom_img_textSec .content_div_sec ol li p {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 22px;
    line-height: 1.375rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .two_colom_img_textSec .content_div_sec ol li p {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 20px;
    line-height: 1.25rem;
    margin-bottom: 12px;
  }
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .two_colom_img_textSec .content_div_sec ol li p {
    margin: 0;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .two_colom_img_textSec .content_div_sec ol li p {
    margin: 0;
    font-size: 18px;
    font-size: 1.125rem;
  }
}
@media (max-width: 767px) {
  .two_colom_img_textSec .content_div_sec ol li p {
    margin: 0;
    font-size: 17px;
    font-size: 1.0625rem;
  }
}
.two_colom_img_textSec .content_div_sec ol li div:nth-child(1) {
  padding-right: 15px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .two_colom_img_textSec .content_div_sec ol li div:nth-child(1) {
    padding-right: 10px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .two_colom_img_textSec .content_div_sec ol li div:nth-child(1) {
    padding-right: 8px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .two_colom_img_textSec .content_div_sec ol li:nth-child(1) {
    padding-left: 0px;
  }
}
.two_colom_img_textSec .content_div_sec ol li i {
  font-weight: 500;
  color: #3454b2;
  font-size: 45px;
}
@media (min-width: 768px) and (max-width: 999px) {
  .two_colom_img_textSec .content_div_sec ol li i {
    font-size: 35px;
  }
}
@media (max-width: 767px) {
  .two_colom_img_textSec .content_div_sec ol li i {
    font-size: 35px;
  }
}
@media (max-width: 767px) {
  .two_colom_img_textSec .content_div_sec .gg2btn {
    margin-top: 0;
  }
}
.two_colom_img_textSec.leftImg_rightContent .img_div_sec {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}
@media (max-width: 767px) {
  .two_colom_img_textSec.leftImg_rightContent .img_div_sec {
    padding: 0px;
  }
}
.two_colom_img_textSec.leftImg_rightContent .content_div_sec {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}
@media (max-width: 767px) {
  .two_colom_img_textSec.leftImg_rightContent .content_div_sec {
    padding-left: 0px;
  }
}
.two_colom_img_textSec.rightImg_leftcontent .img_div_sec {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}
@media (max-width: 767px) {
  .two_colom_img_textSec.rightImg_leftcontent .img_div_sec {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    padding: 0px;
  }
}
.two_colom_img_textSec.rightImg_leftcontent .content_div_sec {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}
@media (max-width: 767px) {
  .two_colom_img_textSec.rightImg_leftcontent .content_div_sec {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    padding-left: 0px;
  }
}

.three_column_img_content_sec .card {
  border: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: none;
}
@media (min-width: 768px) and (max-width: 999px) {
  .three_column_img_content_sec .card img {
    max-width: 40%;
  }
}
@media (max-width: 767px) {
  .three_column_img_content_sec .card img {
    max-width: 30%;
  }
}
.three_column_img_content_sec .card .card-body {
  text-align: center;
  padding-bottom: 0;
}
@media (min-width: 768px) and (max-width: 999px) {
  .three_column_img_content_sec .card .card-body {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media (max-width: 767px) {
  .three_column_img_content_sec .card .card-body {
    padding-bottom: 15px;
    padding-top: 10px;
  }
}
.three_column_img_content_sec .card .card-body strong {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 28px;
  line-height: 1.75rem;
  font-weight: 700;
  font-family: "Sarabun";
  color: #393939;
  display: block;
  margin-bottom: 10px;
  letter-spacing: -0.1px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .three_column_img_content_sec .card .card-body strong {
    font-size: 22px;
    font-size: 1.375rem;
    line-height: 26px;
    line-height: 1.625rem;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .three_column_img_content_sec .card .card-body strong {
    font-size: 16px;
    font-size: 1rem;
    line-height: 22px;
    line-height: 1.375rem;
  }
  .three_column_img_content_sec .card .card-body strong br {
    display: none;
  }
}
@media (max-width: 767px) {
  .three_column_img_content_sec .card .card-body strong {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 24px;
    line-height: 1.5rem;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  .three_column_img_content_sec .card .card-body strong br {
    display: none;
  }
}
.three_column_img_content_sec .card .card-body h3 {
  font-size: 50px;
  font-size: 3.125rem;
  line-height: 50px;
  line-height: 3.125rem;
  color: #393939;
  font-weight: 700;
  font-family: "PT Sans";
  margin-bottom: 22px;
  text-align: left;
  letter-spacing: -0.1px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .three_column_img_content_sec .card .card-body h3 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 42px;
    line-height: 2.625rem;
  }
  .three_column_img_content_sec .card .card-body h3 br {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .three_column_img_content_sec .card .card-body h3 {
    font-size: 27px;
    font-size: 1.6875rem;
    line-height: 30px;
    line-height: 1.875rem;
  }
  .three_column_img_content_sec .card .card-body h3 br {
    display: none;
  }
}
@media (max-width: 767px) {
  .three_column_img_content_sec .card .card-body h3 {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 26px;
    line-height: 1.625rem;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  .three_column_img_content_sec .card .card-body h3 br {
    display: none;
  }
}
.three_column_img_content_sec .card .card-body h4 {
  margin-bottom: 8px;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 30px;
  line-height: 1.875rem;
  color: #3454b2;
  font-weight: 500;
  font-family: "Sarabun";
  letter-spacing: -0.1px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .three_column_img_content_sec .card .card-body h4 br {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .three_column_img_content_sec .card .card-body h4 {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 22px;
    line-height: 1.375rem;
    margin-bottom: 8px;
  }
  .three_column_img_content_sec .card .card-body h4 br {
    display: none;
  }
}
@media (max-width: 767px) {
  .three_column_img_content_sec .card .card-body h4 {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 22px;
    line-height: 1.375rem;
    margin-bottom: 8px;
    margin-top: 5px;
  }
  .three_column_img_content_sec .card .card-body h4 br {
    display: none;
  }
}
.three_column_img_content_sec .card .card-body p {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 25px;
  line-height: 1.5625rem;
  font-weight: 400;
  font-family: "Sarabun";
  color: #525252;
  letter-spacing: -0.1px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .three_column_img_content_sec .card .card-body p {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 23px;
    line-height: 1.4375rem;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .three_column_img_content_sec .card .card-body p {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 22px;
    line-height: 1.375rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .three_column_img_content_sec .card .card-body p {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 20px;
    line-height: 1.25rem;
    margin-bottom: 12px;
  }
}

.fullwidth_content h2 {
  font-size: 50px;
  font-size: 3.125rem;
  line-height: 56px;
  line-height: 3.5rem;
  color: #393939;
  font-weight: 700;
  font-family: "PT Sans";
  margin-bottom: 18px;
  letter-spacing: -0.1px;
  text-align: center;
  margin-bottom: 5px;
  color: #393939;
}
@media (min-width: 1300px) and (max-width: 1450px) {
  .fullwidth_content h2 {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 62px;
    line-height: 3.875rem;
  }
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .fullwidth_content h2 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 45px;
    line-height: 2.8125rem;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .fullwidth_content h2 {
    font-size: 35px;
    font-size: 2.1875rem;
    line-height: 34px;
    line-height: 2.125rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .fullwidth_content h2 {
    font-size: 32px;
    font-size: 2rem;
    line-height: 25px;
    line-height: 1.5625rem;
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .fullwidth_content h2 {
    line-height: 32px;
  }
}
.fullwidth_content h3 {
  font-size: 40px;
  font-size: 2.5rem;
  line-height: 47px;
  line-height: 2.9375rem;
  color: #393939;
  font-weight: 700;
  font-family: "PT Sans";
  margin-bottom: 15px;
  text-align: left;
  letter-spacing: -0.1px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .fullwidth_content h3 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 42px;
    line-height: 2.625rem;
    margin-bottom: 8px;
  }
  .fullwidth_content h3 br {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .fullwidth_content h3 {
    font-size: 34px;
    font-size: 2.125rem;
    line-height: 34px;
    line-height: 2.125rem;
    margin-bottom: 12px;
  }
  .fullwidth_content h3 br {
    display: none;
  }
}
@media (max-width: 767px) {
  .fullwidth_content h3 {
    font-size: 27px;
    font-size: 1.6875rem;
    line-height: 28px;
    line-height: 1.75rem;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  .fullwidth_content h3 br {
    display: none;
  }
}
.fullwidth_content p {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 25px;
  line-height: 1.5625rem;
  font-weight: 400;
  font-family: "Sarabun";
  color: #525252;
  letter-spacing: -0.1px;
  text-align: center;
  margin-bottom: 35px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .fullwidth_content p {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 23px;
    line-height: 1.4375rem;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .fullwidth_content p {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 22px;
    line-height: 1.375rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .fullwidth_content p {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 20px;
    line-height: 1.25rem;
    margin-bottom: 12px;
  }
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .fullwidth_content p {
    margin-bottom: 30px;
  }
}
.fullwidth_content h5 {
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-weight: 700;
  font-family: "Sarabun";
  color: #393939;
  display: block;
  text-align: center;
  margin-bottom: 25px;
  letter-spacing: -0.1px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .fullwidth_content h5 {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 22px;
    line-height: 1.375rem;
    margin-bottom: 20px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .fullwidth_content h5 {
    font-size: 26px;
    font-size: 1.625rem;
    line-height: 22px;
    line-height: 1.375rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .fullwidth_content h5 {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 22px;
    line-height: 1.375rem;
    margin-bottom: 10px;
  }
}
.fullwidth_content strong {
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-weight: 700;
  font-family: "Sarabun";
  color: #393939;
  display: block;
  text-align: center;
  margin-bottom: 25px;
  letter-spacing: -0.1px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .fullwidth_content strong {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 22px;
    line-height: 1.375rem;
    margin-bottom: 5px;
    display: block;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .fullwidth_content strong {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 22px;
    line-height: 1.375rem;
    display: block;
    margin-bottom: 5px;
  }
}
@media (max-width: 767px) {
  .fullwidth_content strong {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 22px;
    line-height: 1.375rem;
  }
}

.gg2btn {
  color: #ffffff;
  background: #fe5e20;
  font-family: "Sarabun";
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  border-radius: 5px;
  padding: 0 55px;
  display: inline-block;
  line-height: 60px;
  line-height: 3.75rem;
  border: 1px solid transparent;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-top: 12px;
  transition: all 500ms ease-in-out;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  position: relative;
}
.gg2btn:hover {
  color: #ffffff;
  background: #3454b2;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .gg2btn {
    font-size: 16px;
    font-size: 1rem;
    line-height: 50px;
    line-height: 3.125rem;
    padding: 0 45px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .gg2btn {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 43px;
    line-height: 2.6875rem;
    padding: 0 38px;
  }
}
@media (max-width: 767px) {
  .gg2btn {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 43px;
    line-height: 2.6875rem;
    padding: 0 37px;
  }
}

.gg1btn {
  color: #ffffff;
  background: #3454b2;
  font-family: "Sarabun";
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  border-radius: 5px;
  padding: 0 55px;
  display: inline-block;
  line-height: 60px;
  line-height: 3.75rem;
  border: 1px solid transparent;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-top: 12px;
  transition: all 500ms ease-in-out;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  position: relative;
  cursor: pointer;
}
.gg1btn:hover {
  color: #ffffff;
  background: #fe5e20;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .gg1btn {
    font-size: 16px;
    font-size: 1rem;
    line-height: 50px;
    line-height: 3.125rem;
    padding: 0 45px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .gg1btn {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 43px;
    line-height: 2.6875rem;
    padding: 0 38px;
  }
}
@media (max-width: 767px) {
  .gg1btn {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 43px;
    line-height: 2.6875rem;
    padding: 0 37px;
  }
}

.error_details_sec {
  padding-top: 200px;
  padding-bottom: 100px;
}
.error_details_sec h3 {
  font-size: 27px;
  font-size: 1.6875rem;
  line-height: 56px;
  line-height: 3.5rem;
  color: #fe5e20;
  font-weight: 400;
  font-family: "PT Sans";
}
.error_details_sec h2 {
  font-size: 70px;
  font-size: 4.375rem;
  line-height: 70px;
  line-height: 4.375rem;
  color: #393939;
  font-weight: 700;
  font-family: "PT Sans";
  text-transform: uppercase;
}
.error_details_sec p {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 25px;
  line-height: 1.5625rem;
  font-weight: 400;
  font-family: "Sarabun";
  color: #525252;
  letter-spacing: -0.1px;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 27px;
  line-height: 1.6875rem;
  font-weight: 500;
  font-family: "Sarabun";
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .error_details_sec p {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 23px;
    line-height: 1.4375rem;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .error_details_sec p {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 22px;
    line-height: 1.375rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .error_details_sec p {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 20px;
    line-height: 1.25rem;
    margin-bottom: 12px;
  }
}

.portfolio_details_info_sec h2 {
  font-size: 50px;
  font-size: 3.125rem;
  line-height: 56px;
  line-height: 3.5rem;
  color: #393939;
  font-weight: 700;
  font-family: "PT Sans";
  margin-bottom: 18px;
  letter-spacing: -0.1px;
  text-align: center;
}
@media (min-width: 1300px) and (max-width: 1450px) {
  .portfolio_details_info_sec h2 {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 62px;
    line-height: 3.875rem;
  }
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .portfolio_details_info_sec h2 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 45px;
    line-height: 2.8125rem;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .portfolio_details_info_sec h2 {
    font-size: 35px;
    font-size: 2.1875rem;
    line-height: 34px;
    line-height: 2.125rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .portfolio_details_info_sec h2 {
    font-size: 32px;
    font-size: 2rem;
    line-height: 25px;
    line-height: 1.5625rem;
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .portfolio_details_info_sec h2 {
    font-size: 28px;
    font-size: 1.75rem;
  }
}
.portfolio_details_info_sec p {
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-weight: 400;
  font-family: "Sarabun";
  color: #525252;
  text-align: center;
  margin-bottom: 35px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .portfolio_details_info_sec p {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 23px;
    line-height: 1.4375rem;
    margin-bottom: 40px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .portfolio_details_info_sec p {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 23px;
    line-height: 1.4375rem;
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .portfolio_details_info_sec p {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 19px;
    line-height: 1.1875rem;
    margin-bottom: 16px;
  }
}
.portfolio_details_info_sec .row > div {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .portfolio_details_info_sec .row > div {
    margin-bottom: 25px;
  }
}
.portfolio_details_info_sec .card {
  padding: 20px;
  padding-bottom: 25px;
  border: none;
  border-radius: 15px;
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  height: 100%;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .portfolio_details_info_sec .card {
    padding-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .portfolio_details_info_sec .card {
    padding-bottom: 15px;
  }
}
.portfolio_details_info_sec .card img {
  border-radius: 10px;
  max-width: 100%;
  width: 100%;
}
.portfolio_details_info_sec .card .card-body {
  padding-left: 0;
  padding-top: 28px;
}
@media (max-width: 767px) {
  .portfolio_details_info_sec .card .card-body {
    padding-top: 20px;
  }
}
.portfolio_details_info_sec .card .card-body h5 {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 21px;
  line-height: 1.3125rem;
  color: #0048b6;
  font-weight: 700;
  font-family: "PT Sans";
  text-align: left;
  padding-bottom: 10px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .portfolio_details_info_sec .card .card-body h5 {
    font-size: 24px;
    font-size: 1.5rem;
  }
}
@media (max-width: 767px) {
  .portfolio_details_info_sec .card .card-body h5 {
    font-size: 22px;
    font-size: 1.375rem;
  }
}
.portfolio_details_info_sec .card .card-body p {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 25px;
  line-height: 1.5625rem;
  font-weight: 400;
  font-family: "Sarabun";
  color: #525252;
  letter-spacing: -0.1px;
  color: #898989;
  text-align: left;
  margin-bottom: 20px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .portfolio_details_info_sec .card .card-body p {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 23px;
    line-height: 1.4375rem;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .portfolio_details_info_sec .card .card-body p {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 22px;
    line-height: 1.375rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .portfolio_details_info_sec .card .card-body p {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 20px;
    line-height: 1.25rem;
    margin-bottom: 12px;
  }
}
.portfolio_details_info_sec .card .card-body ul {
  margin: 0;
  margin-top: 25px;
}
.portfolio_details_info_sec .card .card-body ul li {
  list-style-type: none;
  display: inline-block;
}
.portfolio_details_info_sec .card .card-body ul li img {
  border-radius: 0;
}
.portfolio_details_info_sec .portfolio_button_sec {
  text-align: center;
  margin-top: 45px;
}
@media (max-width: 767px) {
  .portfolio_details_info_sec .portfolio_button_sec {
    margin-top: 20px;
  }
}
.portfolio_details_info_sec .portfolio_button_sec a {
  color: #ffffff;
  background: #3454b2;
  font-family: "Sarabun";
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  border-radius: 5px;
  padding: 0 55px;
  display: inline-block;
  line-height: 60px;
  line-height: 3.75rem;
  border: 1px solid transparent;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-top: 12px;
  transition: all 500ms ease-in-out;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  position: relative;
}
.portfolio_details_info_sec .portfolio_button_sec a:hover {
  color: #ffffff;
  background: #fe5e20;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .portfolio_details_info_sec .portfolio_button_sec a {
    font-size: 16px;
    font-size: 1rem;
    line-height: 50px;
    line-height: 3.125rem;
    padding: 0 45px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .portfolio_details_info_sec .portfolio_button_sec a {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 43px;
    line-height: 2.6875rem;
    padding: 0 38px;
  }
}
@media (max-width: 767px) {
  .portfolio_details_info_sec .portfolio_button_sec a {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 43px;
    line-height: 2.6875rem;
    padding: 0 37px;
  }
}
@media (max-width: 767px) {
  .portfolio_details_info_sec .prot_button_sec {
    margin: 0px !important;
  }
  .portfolio_details_info_sec .prot_button_sec .gg1btn {
    margin-top: 0px;
  }
}

@media (min-width: 1000px) and (max-width: 1299px) {
  .servicesTab_section .tab_section .nav {
    padding-bottom: 45px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .servicesTab_section .tab_section .nav {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding-bottom: 35px;
  }
}
.servicesTab_section .tab_section .nav .nav-item {
  padding-right: 10px;
}
@media (max-width: 767px) {
  .servicesTab_section .tab_section .nav .nav-item {
    margin-bottom: 12px;
    width: 100%;
  }
}
.servicesTab_section .tab_section .nav .nav-item .nav-link {
  padding: 25px 40px;
  -webkit-box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
  background: #ffffff;
  border-radius: 10px;
  color: #7d7d7d;
  font-size: 23px;
  font-size: 1.4375rem;
  font-weight: 600;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .servicesTab_section .tab_section .nav .nav-item .nav-link {
    padding: 18px 29px;
    font-size: 20px;
    font-size: 1.25rem;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .servicesTab_section .tab_section .nav .nav-item .nav-link {
    padding: 14px 20px;
    font-size: 17px;
    font-size: 1.0625rem;
  }
}
@media (max-width: 767px) {
  .servicesTab_section .tab_section .nav .nav-item .nav-link {
    font-size: 16px;
    font-size: 1rem;
    padding: 13px 0px;
    width: 100%;
  }
}
.servicesTab_section .tab_section .nav .nav-item .nav-link:hover {
  background: #3454b2;
  color: #ffffff;
}
.servicesTab_section .tab_section .nav .nav-item .nav-link.active {
  background: #3454b2;
  color: #ffffff;
}
.servicesTab_section .tab_section .accordion-item {
  border: none;
  border-radius: 10px;
  margin-top: 10px;
}
@media (min-width: 767px) {
  .servicesTab_section .tab_section .accordion-item:first-child {
    margin-top: 0px;
  }
}
.servicesTab_section .tab_section .accordion-item .accordion-header {
  margin: 0;
  position: relative;
}
.servicesTab_section .tab_section .accordion-item .accordion-header .accordion-button {
  background: #ffffff;
  color: #393939;
  cursor: pointer;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #d7d7d7;
}
.servicesTab_section .tab_section .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  border: none;
  border: 1px solid #ffffff;
}
@media (max-width: 767px) {
  .servicesTab_section .tab_section .accordion-item .accordion-header .accordion-button:not(.collapsed) {
    border-radius: 10px !important;
  }
}
.servicesTab_section .tab_section .accordion-item .accordion-header .accordion-button::after {
  content: "+";
  position: absolute;
  right: 7px;
  top: 4px;
  font-size: 22px;
  font-size: 1.375rem;
  background-image: none;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .servicesTab_section .tab_section .accordion-item .accordion-header .accordion-button::after {
    top: 17px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .servicesTab_section .tab_section .accordion-item .accordion-header .accordion-button::after {
    top: 14px;
  }
}
@media (max-width: 767px) {
  .servicesTab_section .tab_section .accordion-item .accordion-header .accordion-button::after {
    top: 9px;
  }
}
.servicesTab_section .tab_section .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  content: "-";
  top: 4px;
  font-size: 37px;
  font-size: 2.3125rem;
  background-image: none;
  -webkit-transform: none;
          transform: none;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .servicesTab_section .tab_section .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
    top: 12px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .servicesTab_section .tab_section .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
    top: 14px;
  }
}
@media (max-width: 767px) {
  .servicesTab_section .tab_section .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
    top: 9px;
  }
}
.servicesTab_section .tab_section .accordion-item .accordion-body {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 25px;
  line-height: 1.5625rem;
  font-weight: 400;
  font-family: "Sarabun";
  color: #525252;
  letter-spacing: -0.1px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .servicesTab_section .tab_section .accordion-item .accordion-body {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 23px;
    line-height: 1.4375rem;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .servicesTab_section .tab_section .accordion-item .accordion-body {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 22px;
    line-height: 1.375rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .servicesTab_section .tab_section .accordion-item .accordion-body {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 20px;
    line-height: 1.25rem;
    margin-bottom: 12px;
  }
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .servicesTab_section .tab_section .accordion-item .accordion-body {
    margin-bottom: 0px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .servicesTab_section .tab_section .accordion-item .accordion-body {
    margin-bottom: 0px;
  }
}
@media (max-width: 767px) {
  .servicesTab_section .tab_section .accordion-item .accordion-body {
    padding-top: 5px;
    margin-bottom: 0px;
  }
}
.servicesTab_section .tab_section button {
  font-weight: 500;
  font-family: "Sarabun";
  font-size: 24px;
  font-size: 1.5rem;
  border-radius: 10px !important;
  padding-top: 5px;
  padding-bottom: 5px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .servicesTab_section .tab_section button {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .servicesTab_section .tab_section button {
    font-size: 20px;
    font-size: 1.25rem;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .servicesTab_section .tab_section button {
    font-size: 16px;
    font-size: 1rem;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 0 !important;
  }
}
.servicesTab_section .tab_section button:focus {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  background: none;
}

.client_review_sec h2 {
  font-size: 50px;
  font-size: 3.125rem;
  line-height: 56px;
  line-height: 3.5rem;
  color: #393939;
  font-weight: 700;
  font-family: "PT Sans";
  margin-bottom: 18px;
  letter-spacing: -0.1px;
  text-align: center;
  margin-bottom: 60px;
}
@media (min-width: 1300px) and (max-width: 1450px) {
  .client_review_sec h2 {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 62px;
    line-height: 3.875rem;
  }
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .client_review_sec h2 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 45px;
    line-height: 2.8125rem;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .client_review_sec h2 {
    font-size: 35px;
    font-size: 2.1875rem;
    line-height: 34px;
    line-height: 2.125rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .client_review_sec h2 {
    font-size: 32px;
    font-size: 2rem;
    line-height: 25px;
    line-height: 1.5625rem;
    margin-bottom: 10px;
  }
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .client_review_sec h2 {
    margin-bottom: 50px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .client_review_sec h2 {
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .client_review_sec h2 {
    margin-bottom: 45px;
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 30px;
  }
}
.client_review_sec .row > div {
  margin-bottom: 30px;
  height: 100%;
}
@media (min-width: 768px) and (max-width: 999px) {
  .client_review_sec .row > div {
    margin-bottom: 45px;
  }
}
@media (max-width: 767px) {
  .client_review_sec .row > div {
    margin-bottom: 40px;
  }
}
.client_review_sec .card {
  border-radius: 15px;
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  padding: 20px;
  border: none;
  position: relative;
  padding-bottom: 30px;
  height: 100%;
}
@media (max-width: 767px) {
  .client_review_sec .card {
    padding: 15px;
  }
}
.client_review_sec .card .img_sec {
  position: absolute;
  top: -22px;
  left: 35px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .client_review_sec .card .img_sec {
    left: 22px;
  }
}
@media (max-width: 767px) {
  .client_review_sec .card .img_sec {
    left: 22px;
  }
}
.client_review_sec .card .img_sec img {
  max-width: 60px;
}
.client_review_sec .card .card-body {
  padding-top: 45px;
  min-height: 275px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .client_review_sec .card .card-body {
    padding: 45px 0 0 0;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .client_review_sec .card .card-body {
    padding: 45px 0 0 0;
    min-height: 235px;
  }
}
@media (max-width: 767px) {
  .client_review_sec .card .card-body {
    padding: 45px 0 0 0;
    min-height: 0px;
  }
}
.client_review_sec .card .card-body h5 {
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 28px;
  line-height: 1.75rem;
  color: #393939;
  font-weight: 700;
  font-family: "PT Sans";
  text-align: left;
  padding-bottom: 10px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .client_review_sec .card .card-body h5 {
    font-size: 24px;
    font-size: 1.5rem;
  }
}
@media (max-width: 767px) {
  .client_review_sec .card .card-body h5 {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 24px;
    line-height: 1.5rem;
  }
}
.client_review_sec .card .card-body p {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 25px;
  line-height: 1.5625rem;
  font-weight: 400;
  font-family: "Sarabun";
  color: #525252;
  letter-spacing: -0.1px;
  margin-bottom: 25px;
  text-align: left;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .client_review_sec .card .card-body p {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 23px;
    line-height: 1.4375rem;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .client_review_sec .card .card-body p {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 22px;
    line-height: 1.375rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .client_review_sec .card .card-body p {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 20px;
    line-height: 1.25rem;
    margin-bottom: 12px;
  }
}
.client_review_sec .card .card-body strong {
  display: block;
  text-align: end;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 26px;
  line-height: 1.625rem;
  font-weight: 700;
  font-family: "Sarabun";
  color: #525252;
}
@media (max-width: 767px) {
  .client_review_sec .card .card-body strong {
    font-size: 15px;
    font-size: 0.9375rem;
  }
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .client_review_sec .card .card-body strong {
    font-size: 17px;
    font-size: 1.0625rem;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .client_review_sec .card .card-body strong {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.expertise_section .fullWidth_content h2 {
  font-size: 50px;
  font-size: 3.125rem;
  line-height: 56px;
  line-height: 3.5rem;
  color: #393939;
  font-weight: 700;
  font-family: "PT Sans";
  margin-bottom: 18px;
  letter-spacing: -0.1px;
  text-align: center;
  margin-bottom: 5px;
  color: #393939;
}
@media (min-width: 1300px) and (max-width: 1450px) {
  .expertise_section .fullWidth_content h2 {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 62px;
    line-height: 3.875rem;
  }
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .expertise_section .fullWidth_content h2 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 45px;
    line-height: 2.8125rem;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .expertise_section .fullWidth_content h2 {
    font-size: 35px;
    font-size: 2.1875rem;
    line-height: 34px;
    line-height: 2.125rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .expertise_section .fullWidth_content h2 {
    font-size: 32px;
    font-size: 2rem;
    line-height: 25px;
    line-height: 1.5625rem;
    margin-bottom: 10px;
  }
}
.expertise_section .fullWidth_content h3 {
  font-size: 40px;
  font-size: 2.5rem;
  line-height: 47px;
  line-height: 2.9375rem;
  color: #393939;
  font-weight: 700;
  font-family: "PT Sans";
  margin-bottom: 15px;
  margin-top: 30px;
  text-align: center;
  color: #393a62;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .expertise_section .fullWidth_content h3 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 42px;
    line-height: 2.625rem;
    margin-bottom: 8px;
  }
  .expertise_section .fullWidth_content h3 br {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .expertise_section .fullWidth_content h3 {
    font-size: 34px;
    font-size: 2.125rem;
    line-height: 34px;
    line-height: 2.125rem;
    margin-bottom: 12px;
  }
  .expertise_section .fullWidth_content h3 br {
    display: none;
  }
}
@media (max-width: 767px) {
  .expertise_section .fullWidth_content h3 {
    font-size: 27px;
    font-size: 1.6875rem;
    line-height: 28px;
    line-height: 1.75rem;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  .expertise_section .fullWidth_content h3 br {
    display: none;
  }
}
.expertise_section .fullWidth_content p {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 25px;
  line-height: 1.5625rem;
  font-weight: 400;
  font-family: "Sarabun";
  color: #525252;
  letter-spacing: -0.1px;
  text-align: center;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .expertise_section .fullWidth_content p {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 23px;
    line-height: 1.4375rem;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .expertise_section .fullWidth_content p {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 22px;
    line-height: 1.375rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .expertise_section .fullWidth_content p {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 20px;
    line-height: 1.25rem;
    margin-bottom: 12px;
  }
}
.expertise_section .fullWidth_content p span {
  display: block;
  text-align: start;
  margin-top: 20px;
}
.expertise_section .fullWidth_content strong {
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-weight: 700;
  font-family: "Sarabun";
  color: #393939;
  display: block;
  text-align: center;
  margin-bottom: 25px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .expertise_section .fullWidth_content strong {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 22px;
    line-height: 1.375rem;
    margin-bottom: 5px;
    display: block;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .expertise_section .fullWidth_content strong {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 22px;
    line-height: 1.375rem;
    display: block;
    margin-bottom: 5px;
  }
}
@media (max-width: 767px) {
  .expertise_section .fullWidth_content strong {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 22px;
    line-height: 1.375rem;
  }
}
.expertise_section .fullWidth_content ul li {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 28px;
  line-height: 1.75rem;
  font-weight: 500;
  font-family: "Sarabun";
  position: relative;
  padding-left: 30px;
  list-style-type: none;
  padding-top: 18px;
}
.expertise_section .fullWidth_content ul li::before {
  content: "\f35a";
  position: absolute;
  font-family: "Font Awesome 5 free";
  top: 19px;
  left: 0;
}
@media (min-width: 768px) and (max-width: 999px) {
  .expertise_section .fullWidth_content ul li {
    font-size: 16px;
    font-size: 1rem;
    line-height: 22px;
    line-height: 1.375rem;
    padding-left: 25px;
  }
  .expertise_section .fullWidth_content ul li::before {
    top: 20px;
  }
}
@media (max-width: 767px) {
  .expertise_section .fullWidth_content ul li {
    font-size: 16px;
    font-size: 1rem;
    line-height: 22px;
    line-height: 1.375rem;
    padding-left: 25px;
  }
  .expertise_section .fullWidth_content ul li::before {
    top: 20px;
    left: 0;
  }
}

.blog_details_sec .card_body_sec {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .blog_details_sec .card_body_sec {
    margin-bottom: 20px;
  }
}
.blog_details_sec .card {
  padding: 25px;
  padding-bottom: 25px;
  border: none;
  border-radius: 15px;
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  height: 100%;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .blog_details_sec .card {
    padding-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .blog_details_sec .card {
    padding: 18px;
  }
}
.blog_details_sec .card img {
  border-radius: 10px;
  width: 100%;
}
.blog_details_sec .card .card-body {
  padding-left: 0;
  padding-top: 25px;
  padding-right: 0;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .blog_details_sec .card .card-body {
    padding-top: 15px;
  }
}
@media (max-width: 767px) {
  .blog_details_sec .card .card-body {
    padding-top: 15px;
  }
}
.blog_details_sec .card .card-body h5 {
  line-height: inherit;
}
.blog_details_sec .card .card-body h5 a {
  font-size: 23px;
  font-size: 1.4375rem;
  line-height: 26px;
  line-height: 1.625rem;
  color: #393a62;
  font-weight: 700;
  font-family: "PT Sans";
  margin-bottom: 10px;
  text-align: left;
  transition: all 500ms ease-in-out;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .blog_details_sec .card .card-body h5 a {
    font-size: 19px;
    font-size: 1.1875rem;
    line-height: 18px;
    line-height: 1.125rem;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .blog_details_sec .card .card-body h5 a {
    font-size: 21px;
    font-size: 1.3125rem;
    line-height: 27px;
    line-height: 1.6875rem;
  }
}
@media (max-width: 767px) {
  .blog_details_sec .card .card-body h5 a {
    font-size: 16px;
    font-size: 1rem;
    line-height: 16px;
    line-height: 1rem;
  }
}
.blog_details_sec .card .card-body h5 a:hover {
  color: #fe6c3a;
}
.blog_details_sec .card .card-body p {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 25px;
  line-height: 1.5625rem;
  font-weight: 400;
  font-family: "Sarabun";
  color: #525252;
  letter-spacing: -0.1px;
  color: #898989;
  text-align: left;
  margin-bottom: 20px;
  margin-top: 10px;
  font-size: 19px;
  font-size: 1.1875rem;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .blog_details_sec .card .card-body p {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 23px;
    line-height: 1.4375rem;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .blog_details_sec .card .card-body p {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 22px;
    line-height: 1.375rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .blog_details_sec .card .card-body p {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 20px;
    line-height: 1.25rem;
    margin-bottom: 12px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .blog_details_sec .card .card-body p {
    font-size: 17px;
    font-size: 1.0625rem;
  }
}
.blog_details_sec .card .card-body ul {
  margin: 0;
  margin-top: 25px;
}
.blog_details_sec .card .card-body ul li {
  list-style-type: none;
  display: inline-block;
}
.blog_details_sec .card .card-body ul li img {
  border-radius: 0;
}
.blog_details_sec .card .card-body .only_blog {
  color: #ffffff;
  background: #fe5e20;
  font-family: "Sarabun";
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  border-radius: 5px;
  padding: 0 55px;
  display: inline-block;
  line-height: 60px;
  line-height: 3.75rem;
  border: 1px solid transparent;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-top: 12px;
  transition: all 500ms ease-in-out;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  position: relative;
  line-height: 50px;
  line-height: 3.125rem;
  font-size: 16px;
  font-size: 1rem;
  padding: 0 42px;
  border-radius: 50px;
}
.blog_details_sec .card .card-body .only_blog:hover {
  color: #ffffff;
  background: #3454b2;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .blog_details_sec .card .card-body .only_blog {
    font-size: 16px;
    font-size: 1rem;
    line-height: 50px;
    line-height: 3.125rem;
    padding: 0 45px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .blog_details_sec .card .card-body .only_blog {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 43px;
    line-height: 2.6875rem;
    padding: 0 38px;
  }
}
@media (max-width: 767px) {
  .blog_details_sec .card .card-body .only_blog {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 43px;
    line-height: 2.6875rem;
    padding: 0 37px;
  }
}
@media (max-width: 767px) {
  .blog_details_sec .card .card-body .only_blog {
    line-height: 41px;
    line-height: 2.5625rem;
    padding: 0 32px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .blog_details_sec .card .card-body .only_blog {
    line-height: 44px;
    line-height: 2.75rem;
    font-size: 15px;
    font-size: 0.9375rem;
    padding: 0 36px;
  }
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .blog_details_sec .card .card-body .only_blog {
    line-height: 43px;
    line-height: 2.6875rem;
    font-size: 15px;
    font-size: 0.9375rem;
    padding: 0 36px;
  }
}
.blog_details_sec .blog_details_left_sec .postContent p {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 25px;
  line-height: 1.5625rem;
  font-weight: 400;
  font-family: "Sarabun";
  color: #525252;
  letter-spacing: -0.1px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .blog_details_sec .blog_details_left_sec .postContent p {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 23px;
    line-height: 1.4375rem;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .blog_details_sec .blog_details_left_sec .postContent p {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 22px;
    line-height: 1.375rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .blog_details_sec .blog_details_left_sec .postContent p {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 20px;
    line-height: 1.25rem;
    margin-bottom: 12px;
  }
}
.blog_details_sec .blog_details_left_sec .postContent p a {
  font-family: "Sarabun";
  font-weight: 500;
}
.blog_details_sec .blog_details_left_sec .postContent ul li {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 28px;
  line-height: 1.75rem;
  font-weight: 500;
  font-family: "Sarabun";
  list-style-type: none;
  padding-top: 10px;
  padding-left: 0;
}
@media (min-width: 768px) and (max-width: 999px) {
  .blog_details_sec .blog_details_left_sec .postContent ul li {
    font-size: 16px;
    font-size: 1rem;
    line-height: 22px;
    line-height: 1.375rem;
  }
}
@media (max-width: 767px) {
  .blog_details_sec .blog_details_left_sec .postContent ul li {
    font-size: 16px;
    font-size: 1rem;
    line-height: 22px;
    line-height: 1.375rem;
  }
}
.blog_details_sec .blog_details_left_sec .postContent ul li strong {
  font-size: 22px;
  font-size: 1.375rem;
  font-weight: 700;
}
@media (max-width: 767px) {
  .blog_details_sec .blog_details_left_sec .postContent ul li strong {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
.blog_details_sec .blog_details_left_sec .postContent h3 {
  font-size: 40px;
  font-size: 2.5rem;
  line-height: 47px;
  line-height: 2.9375rem;
  color: #393939;
  font-weight: 700;
  font-family: "PT Sans";
  margin-bottom: 15px;
  margin-top: 25px;
  text-align: left;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .blog_details_sec .blog_details_left_sec .postContent h3 {
    font-size: 36px;
    font-size: 2.25rem;
    line-height: 42px;
    line-height: 2.625rem;
    margin-bottom: 8px;
  }
  .blog_details_sec .blog_details_left_sec .postContent h3 br {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .blog_details_sec .blog_details_left_sec .postContent h3 {
    font-size: 29px;
    font-size: 1.8125rem;
    line-height: 32px;
    line-height: 2rem;
    margin-bottom: 12px;
  }
  .blog_details_sec .blog_details_left_sec .postContent h3 br {
    display: none;
  }
}
@media (max-width: 767px) {
  .blog_details_sec .blog_details_left_sec .postContent h3 {
    font-size: 27px;
    font-size: 1.6875rem;
    line-height: 28px;
    line-height: 1.75rem;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  .blog_details_sec .blog_details_left_sec .postContent h3 br {
    display: none;
  }
}
.blog_details_sec .blog_details_left_sec .postContent h2 strong {
  display: block;
  font-size: 40px;
  font-size: 2.5rem;
  line-height: 47px;
  line-height: 2.9375rem;
  color: #393939;
  font-weight: 700;
  font-family: "PT Sans";
  margin-bottom: 15px;
  margin-top: 25px;
  text-align: left;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .blog_details_sec .blog_details_left_sec .postContent h2 strong {
    font-size: 36px;
    font-size: 2.25rem;
    line-height: 42px;
    line-height: 2.625rem;
    margin-bottom: 8px;
  }
  .blog_details_sec .blog_details_left_sec .postContent h2 strong br {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .blog_details_sec .blog_details_left_sec .postContent h2 strong {
    font-size: 29px;
    font-size: 1.8125rem;
    line-height: 32px;
    line-height: 2rem;
    margin-bottom: 12px;
  }
  .blog_details_sec .blog_details_left_sec .postContent h2 strong br {
    display: none;
  }
}
@media (max-width: 767px) {
  .blog_details_sec .blog_details_left_sec .postContent h2 strong {
    font-size: 27px;
    font-size: 1.6875rem;
    line-height: 28px;
    line-height: 1.75rem;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  .blog_details_sec .blog_details_left_sec .postContent h2 strong br {
    display: none;
  }
}
.blog_details_sec .blog_details_left_sec .postContent h2 {
  font-size: 40px;
  font-size: 2.5rem;
  line-height: 47px;
  line-height: 2.9375rem;
  color: #393939;
  font-weight: 700;
  font-family: "PT Sans";
  margin-bottom: 15px;
  margin-top: 25px;
  text-align: left;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .blog_details_sec .blog_details_left_sec .postContent h2 {
    font-size: 36px;
    font-size: 2.25rem;
    line-height: 42px;
    line-height: 2.625rem;
    margin-bottom: 8px;
  }
  .blog_details_sec .blog_details_left_sec .postContent h2 br {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .blog_details_sec .blog_details_left_sec .postContent h2 {
    font-size: 29px;
    font-size: 1.8125rem;
    line-height: 32px;
    line-height: 2rem;
    margin-bottom: 12px;
  }
  .blog_details_sec .blog_details_left_sec .postContent h2 br {
    display: none;
  }
}
@media (max-width: 767px) {
  .blog_details_sec .blog_details_left_sec .postContent h2 {
    font-size: 27px;
    font-size: 1.6875rem;
    line-height: 28px;
    line-height: 1.75rem;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  .blog_details_sec .blog_details_left_sec .postContent h2 br {
    display: none;
  }
}

.contact_form_info_sec .contact_text_wrap {
  text-align: center;
  margin-bottom: 55px;
}
@media (min-width: 768px) and (max-width: 999px) {
  .contact_form_info_sec .contact_text_wrap {
    margin-bottom: 35px;
  }
}
@media (max-width: 767px) {
  .contact_form_info_sec .contact_text_wrap {
    margin-bottom: 30px;
  }
}
.contact_form_info_sec .contact_text_wrap strong {
  font-size: 40px;
  font-size: 2.5rem;
  line-height: 30px;
  line-height: 1.875rem;
  color: #393939;
  font-weight: 400;
  font-family: "PT Sans";
}
@media (min-width: 768px) and (max-width: 999px) {
  .contact_form_info_sec .contact_text_wrap strong {
    margin-bottom: 10px;
    display: block;
  }
}
@media (max-width: 767px) {
  .contact_form_info_sec .contact_text_wrap strong {
    font-size: 30px;
    font-size: 1.875rem;
    display: block;
    margin-bottom: 6px;
  }
}
.contact_form_info_sec .contact_text_wrap h2 {
  font-size: 50px;
  font-size: 3.125rem;
  line-height: 56px;
  line-height: 3.5rem;
  color: #393939;
  font-weight: 700;
  font-family: "PT Sans";
  margin-bottom: 18px;
  letter-spacing: -0.1px;
}
@media (min-width: 1300px) and (max-width: 1450px) {
  .contact_form_info_sec .contact_text_wrap h2 {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 62px;
    line-height: 3.875rem;
  }
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .contact_form_info_sec .contact_text_wrap h2 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 45px;
    line-height: 2.8125rem;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .contact_form_info_sec .contact_text_wrap h2 {
    font-size: 35px;
    font-size: 2.1875rem;
    line-height: 34px;
    line-height: 2.125rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .contact_form_info_sec .contact_text_wrap h2 {
    font-size: 32px;
    font-size: 2rem;
    line-height: 25px;
    line-height: 1.5625rem;
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .contact_form_info_sec .contact_text_wrap h2 {
    font-size: 28px;
    font-size: 1.75rem;
  }
}
.contact_form_info_sec .contact_form_left_sec {
  padding: 60px 45px;
  border-radius: 30px 0px 0px 30px;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .contact_form_info_sec .contact_form_left_sec {
    padding: 60px 35px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .contact_form_info_sec .contact_form_left_sec {
    padding: 38px 28px;
    border-radius: 14px 0px 0px 14px;
  }
}
@media (max-width: 767px) {
  .contact_form_info_sec .contact_form_left_sec {
    border-radius: 15px;
    margin-bottom: 25px;
    padding: 20px 25px;
  }
}
.contact_form_info_sec .contact_form_left_sec h3 {
  color: #ffffff;
  padding-left: 0;
}
@media (max-width: 767px) {
  .contact_form_info_sec .contact_form_left_sec h3 {
    margin-bottom: 25px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .contact_form_info_sec .contact_form_left_sec h3 {
    margin-bottom: 25px;
  }
}
.contact_form_info_sec .contact_form_left_sec ul li {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 15px;
  position: relative;
}
.contact_form_info_sec .contact_form_left_sec ul li:nth-child(1)::before {
  content: "\e906";
  font-weight: 500;
  font-family: "icomoon";
  position: absolute;
  top: 0;
  left: 0;
  color: #ffffff;
  font-size: 40px;
}
@media (min-width: 768px) and (max-width: 999px) {
  .contact_form_info_sec .contact_form_left_sec ul li:nth-child(1)::before {
    font-size: 35px;
  }
}
.contact_form_info_sec .contact_form_left_sec ul li:nth-child(2)::before {
  content: "\e907";
  font-weight: 500;
  font-family: "icomoon";
  position: absolute;
  top: 0;
  left: 0;
  color: #ffffff;
  font-size: 40px;
}
@media (min-width: 768px) and (max-width: 999px) {
  .contact_form_info_sec .contact_form_left_sec ul li:nth-child(2)::before {
    font-size: 35px;
  }
}
.contact_form_info_sec .contact_form_left_sec ul li:nth-child(3)::before {
  content: "\e900";
  font-weight: 500;
  font-family: "icomoon";
  position: absolute;
  top: 0;
  left: 0;
  color: #ffffff;
  font-size: 40px;
}
@media (min-width: 768px) and (max-width: 999px) {
  .contact_form_info_sec .contact_form_left_sec ul li:nth-child(3)::before {
    font-size: 35px;
  }
}
.contact_form_info_sec .contact_form_left_sec ul li > div:nth-child(1) {
  display: none;
}
.contact_form_info_sec .contact_form_left_sec ul li > div:nth-child(2) {
  padding-left: 55px;
}
@media (min-width: 768px) and (max-width: 999px) {
  .contact_form_info_sec .contact_form_left_sec ul li > div:nth-child(2) {
    padding-left: 45px;
  }
}
.contact_form_info_sec .contact_form_left_sec ul li p {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 25px;
  line-height: 1.5625rem;
  font-weight: 400;
  font-family: "Sarabun";
  color: #525252;
  letter-spacing: -0.1px;
  color: #8aa7fa;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .contact_form_info_sec .contact_form_left_sec ul li p {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 23px;
    line-height: 1.4375rem;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .contact_form_info_sec .contact_form_left_sec ul li p {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 22px;
    line-height: 1.375rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .contact_form_info_sec .contact_form_left_sec ul li p {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 20px;
    line-height: 1.25rem;
    margin-bottom: 12px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .contact_form_info_sec .contact_form_left_sec ul li p {
    font-size: 17px;
    font-size: 1.0625rem;
  }
  .contact_form_info_sec .contact_form_left_sec ul li p br {
    display: none;
  }
}
@media (max-width: 767px) {
  .contact_form_info_sec .contact_form_left_sec ul li p {
    font-size: 15px;
    font-size: 0.9375rem;
  }
  .contact_form_info_sec .contact_form_left_sec ul li p br {
    display: none;
  }
}
.contact_form_info_sec .contact_form_left_sec ul li p a {
  color: #8aa7fa;
}
.contact_form_info_sec .contact_form_left_sec ul li strong {
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 30px;
  line-height: 1.875rem;
  color: #ffffff;
  font-weight: 500;
  font-family: "PT Sans";
  display: block;
  margin-bottom: 5px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .contact_form_info_sec .contact_form_left_sec ul li strong {
    font-size: 28px;
    font-size: 1.75rem;
    margin-bottom: 3px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .contact_form_info_sec .contact_form_left_sec ul li strong {
    font-size: 22px;
    font-size: 1.375rem;
    line-height: 28px;
    line-height: 1.75rem;
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .contact_form_info_sec .contact_form_left_sec ul li strong {
    font-size: 24px;
    font-size: 1.5rem;
    line-height: 18px;
    line-height: 1.125rem;
  }
}
.contact_form_info_sec .contact_form_left_sec ul li > div:nth-child(1) {
  padding-right: 20px;
}
.contact_form_info_sec .contact_form_right_sec {
  padding: 0;
  margin-left: -30px;
}
@media (min-width: 768px) and (max-width: 999px) {
  .contact_form_info_sec .contact_form_right_sec {
    margin-left: -15px;
  }
}
@media (max-width: 767px) {
  .contact_form_info_sec .contact_form_right_sec {
    margin-left: 0;
  }
}
.contact_form_info_sec form {
  padding: 38px 35px;
  padding-top: 60px;
  border-radius: 30px;
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.21);
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.21);
  background: #ffffff;
}
@media (min-width: 768px) and (max-width: 999px) {
  .contact_form_info_sec form {
    padding: 38px 15px;
    border-radius: 14px;
  }
}
@media (max-width: 767px) {
  .contact_form_info_sec form {
    padding: 38px 10px;
    border-radius: 15px;
    padding-top: 20px;
  }
}
.contact_form_info_sec form input[type=text],
.contact_form_info_sec form input[type=email],
.contact_form_info_sec form input[type=number],
.contact_form_info_sec form input[type=tel],
.contact_form_info_sec form input[type=phone],
.contact_form_info_sec form textarea {
  background: transparent;
  font-size: 17px;
  font-size: 1.0625rem;
  font-weight: 400;
  font-family: "Sarabun";
  height: 60px;
  padding-left: 20px;
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  color: #000000;
  -webkit-box-shadow: none;
          box-shadow: none;
  margin-bottom: 10px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .contact_form_info_sec form input[type=text],
  .contact_form_info_sec form input[type=email],
  .contact_form_info_sec form input[type=number],
  .contact_form_info_sec form input[type=tel],
  .contact_form_info_sec form input[type=phone],
  .contact_form_info_sec form textarea {
    padding-left: 13px;
  }
}
@media (max-width: 767px) {
  .contact_form_info_sec form input[type=text],
  .contact_form_info_sec form input[type=email],
  .contact_form_info_sec form input[type=number],
  .contact_form_info_sec form input[type=tel],
  .contact_form_info_sec form input[type=phone],
  .contact_form_info_sec form textarea {
    height: 52px;
    font-size: 15px;
    font-size: 0.9375rem;
  }
}
.contact_form_info_sec form .mess_sec {
  margin-top: 15px;
  padding-left: 10px;
}
.contact_form_info_sec form .mess_sec .form-control {
  height: 150px;
}
.contact_form_info_sec form .mess_sec .form-control:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  outline: none;
}
.contact_form_info_sec form input::-webkit-input-placeholder {
  color: #343434;
}
.contact_form_info_sec form input:-moz-placeholder {
  color: #343434;
}
.contact_form_info_sec form input::-moz-placeholder {
  color: #343434;
}
.contact_form_info_sec form input:-ms-input-placeholder {
  color: #343434;
}
.contact_form_info_sec form .gg1btn {
  margin-left: 15px;
}
.contact_form_info_sec form .form-select {
  background: transparent;
  font-size: 17px;
  font-size: 1.0625rem;
  font-weight: 400;
  font-family: "Sarabun";
  height: 60px;
  padding: 0 15px 0 15px;
  border-radius: 10px;
  border: 1px solid #dfdfdf;
  color: #000000;
  -webkit-box-shadow: none;
          box-shadow: none;
  margin-bottom: 10px;
  margin-top: 5px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .contact_form_info_sec form .form-select {
    padding: 0 5px 0 13px;
  }
}
@media (max-width: 767px) {
  .contact_form_info_sec form .form-select {
    height: 52px;
    font-size: 15px;
    font-size: 0.9375rem;
  }
}
.contact_form_info_sec form .form-select:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 2px solid #dfdfdf;
}
.contact_form_info_sec form label {
  font-size: 17px;
  font-size: 1.0625rem;
  font-weight: 500;
  font-family: "Sarabun";
  color: #393939;
  display: block;
  line-height: 28px;
  line-height: 1.75rem;
  margin-bottom: 4px;
  padding-left: 6px;
  position: relative;
}
@media (min-width: 768px) and (max-width: 999px) {
  .contact_form_info_sec form label {
    font-size: 16px;
    font-size: 1rem;
  }
}
@media (max-width: 767px) {
  .contact_form_info_sec form label {
    font-size: 15px;
    font-size: 0.9375rem;
    margin-bottom: 2px;
  }
}
.contact_form_info_sec form label:after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f078";
  position: absolute;
  right: 14px;
  bottom: 16px;
}
.contact_form_info_sec form .form_error {
  color: #ec2e66;
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 22px;
  line-height: 1.375rem;
}
.contact_form_info_sec h3 {
  font-size: 40px;
  font-size: 2.5rem;
  line-height: 40px;
  line-height: 2.5rem;
  color: #393939;
  font-weight: 700;
  font-family: "PT Sans";
  margin-bottom: 20px;
  padding-left: 10px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .contact_form_info_sec h3 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 42px;
    line-height: 2.625rem;
    margin-bottom: 20px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .contact_form_info_sec h3 {
    font-size: 34px;
    font-size: 2.125rem;
    line-height: 34px;
    line-height: 2.125rem;
    margin-bottom: 12px;
  }
}
@media (max-width: 767px) {
  .contact_form_info_sec h3 {
    font-size: 32px;
    font-size: 2rem;
    line-height: 28px;
    line-height: 1.75rem;
    margin-bottom: 20px;
    margin-top: 8px;
  }
}

.thankyou_page .container {
  max-width: 800px;
}
.thankyou_page .thanks_post {
  padding: 0;
}
.thankyou_page .thanks_post .card {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 50px 50px 40px 50px;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.21);
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.21);
  border: 0;
}
@media (max-width: 767px) {
  .thankyou_page .thanks_post .card {
    padding: 50px 20px 40px 20px;
  }
}
@media (max-width: 767px) {
  .thankyou_page .thanks_post .card img {
    max-width: 30%;
  }
}
.thankyou_page .thanks_post .card h3 {
  font-size: 50px;
  font-size: 3.125rem;
  line-height: 56px;
  line-height: 3.5rem;
  color: #393939;
  font-weight: 700;
  font-family: "PT Sans";
  margin-bottom: 18px;
  letter-spacing: -0.1px;
  margin-top: 18px;
}
@media (min-width: 1300px) and (max-width: 1450px) {
  .thankyou_page .thanks_post .card h3 {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 62px;
    line-height: 3.875rem;
  }
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .thankyou_page .thanks_post .card h3 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 45px;
    line-height: 2.8125rem;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .thankyou_page .thanks_post .card h3 {
    font-size: 35px;
    font-size: 2.1875rem;
    line-height: 34px;
    line-height: 2.125rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .thankyou_page .thanks_post .card h3 {
    font-size: 32px;
    font-size: 2rem;
    line-height: 25px;
    line-height: 1.5625rem;
    margin-bottom: 10px;
  }
}
.thankyou_page .thanks_post .card p {
  font-weight: 400;
  font-family: "Sarabun";
  font-size: 25px;
  font-size: 1.5625rem;
  line-height: 32px;
  line-height: 2rem;
  color: #000000;
  text-align: center;
}
@media (min-width: 768px) and (max-width: 999px) {
  .thankyou_page .thanks_post .card p {
    font-size: 23px;
    font-size: 1.4375rem;
    line-height: 30px;
    line-height: 1.875rem;
  }
}
@media (max-width: 767px) {
  .thankyou_page .thanks_post .card p {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 27px;
    line-height: 1.6875rem;
  }
}

.ourProducts__sec h2 {
  font-size: 50px;
  font-size: 3.125rem;
  line-height: 56px;
  line-height: 3.5rem;
  color: #393939;
  font-weight: 700;
  font-family: "PT Sans";
  margin-bottom: 18px;
  letter-spacing: -0.1px;
  text-align: center;
  margin-bottom: 35px;
}
@media (min-width: 1300px) and (max-width: 1450px) {
  .ourProducts__sec h2 {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 62px;
    line-height: 3.875rem;
  }
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .ourProducts__sec h2 {
    font-size: 40px;
    font-size: 2.5rem;
    line-height: 45px;
    line-height: 2.8125rem;
    margin-bottom: 15px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .ourProducts__sec h2 {
    font-size: 35px;
    font-size: 2.1875rem;
    line-height: 34px;
    line-height: 2.125rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .ourProducts__sec h2 {
    font-size: 32px;
    font-size: 2rem;
    line-height: 25px;
    line-height: 1.5625rem;
    margin-bottom: 10px;
  }
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .ourProducts__sec h2 {
    margin-bottom: 28px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .ourProducts__sec h2 {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .ourProducts__sec h2 {
    margin-bottom: 0px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .ourProducts__sec .card_body_sec {
    margin-top: 20px;
  }
}
@media (max-width: 767px) {
  .ourProducts__sec .card_body_sec {
    margin-top: 20px;
  }
}
.ourProducts__sec .card {
  padding: 25px 15px;
  padding-bottom: 25px;
  border: none;
  border-radius: 15px;
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  height: 100%;
}
.ourProducts__sec .card img {
  max-width: 100px;
  width: 100%;
}
.ourProducts__sec .card h4 a {
  font-size: 22px;
  font-size: 1.375rem;
  line-height: 28px;
  line-height: 1.75rem;
  font-weight: 600;
  font-family: "Sarabun";
  color: #393a62;
  transition: all 500ms ease-in-out;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .ourProducts__sec .card h4 a {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 24px;
    line-height: 1.5rem;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .ourProducts__sec .card h4 a {
    font-size: 16px;
    font-size: 1rem;
    line-height: 21px;
    line-height: 1.3125rem;
  }
}
@media (max-width: 767px) {
  .ourProducts__sec .card h4 a {
    font-size: 16px;
    font-size: 1rem;
    line-height: 22px;
    line-height: 1.375rem;
  }
}
.ourProducts__sec .card h4 a:hover {
  color: #fe6c3a;
}
.ourProducts__sec .card h6 {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 23px;
  line-height: 1.4375rem;
  font-weight: 500;
  font-family: "Sarabun";
  color: #000000;
  margin-bottom: 5px;
  text-transform: capitalize;
}
.ourProducts__sec .card p {
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 20px;
  line-height: 1.25rem;
  font-weight: 400;
  font-family: "Sarabun";
  color: #525252;
  margin-bottom: 0px;
}

body {
  font-family: "Sarabun";
  color: #525252;
  background-color: #ffffff;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  -webkit-text-size-adjust: none;
}

mark {
  background-color: transparent;
  color: inherit;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input,
select,
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

@-ms-viewport {
  width: device-width;
}
html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

em,
i {
  font-style: italic;
}

p {
  margin: 0 0 15px 0;
}

sub {
  font-size: 12px;
  font-size: 0.75rem;
  position: relative;
  top: 0.5em;
}

sup {
  font-size: 12px;
  font-size: 0.75rem;
  position: relative;
  top: -0.5em;
}

hr {
  border: 0;
}

blockquote {
  border-left: solid 4px #525252;
  font-style: italic;
  margin: 0 0 15px 0;
  padding: 0.5em 0 0.5em 2em;
}

code {
  background: #525252;
  border-radius: 3px;
  font-family: "Sarabun";
  font-size: 14px;
  font-size: 0.875rem;
  letter-spacing: 0;
  margin: 0 0.25em;
  padding: 0.25em 0.65em;
}

pre {
  -webkit-overflow-scrolling: touch;
  font-family: "Sarabun";
  font-size: 14px;
  font-size: 0.875rem;
  margin: 0 0 15px 0;
}
pre code {
  display: block;
  line-height: 1.75em;
  padding: 1em 1.5em;
  overflow-x: auto;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

::-webkit-input-placeholder {
  color: #525252 !important;
  opacity: 0.6;
}

:-moz-placeholder {
  color: #525252 !important;
  opacity: 0.6;
}

::-moz-placeholder {
  color: #525252 !important;
  opacity: 0.6;
}

:-ms-input-placeholder {
  color: #525252 !important;
  opacity: 0.6;
}

ol {
  list-style: decimal;
  margin: 0 0 15px 0;
  padding-left: 0;
}
ol li {
  padding-left: 0.25em;
}

ul {
  list-style: disc;
  margin: 0 0 15px 0;
  padding-left: 0;
}
ul li {
  padding-left: 0.5em;
}
ul.alt {
  list-style: none;
  padding-left: 0;
}
ul.alt li {
  border-top: solid 1px;
  padding: 0.5em 0;
}
ul.alt li:first-child {
  border-top: 0;
  padding-top: 0;
}

dl {
  margin: 0 0 15px 0;
}

h1 {
  font-family: "Sarabun";
  font-size: 82px;
  font-size: 5.125rem;
  color: #393939;
  font-weight: 700;
}
@media (max-width: 767px) {
  h1 {
    font-size: 38px;
    font-size: 2.375rem;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  h1 {
    font-size: 52px;
    font-size: 3.25rem;
  }
}
@media (min-width: 800px) and (max-width: 1100px) {
  h1 {
    font-size: 62px;
    font-size: 3.875rem;
  }
}

h2 {
  font-family: "Sarabun";
  font-size: 60px;
  font-size: 3.75rem;
  line-height: normal;
  color: #000000;
  margin-bottom: 12px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  h2 {
    font-size: 50px;
    font-size: 3.125rem;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  h2 {
    font-size: 38px;
    font-size: 2.375rem;
  }
}
@media (max-width: 767px) {
  h2 {
    font-size: 30px;
    font-size: 1.875rem;
  }
}
@media (min-width: 800px) and (max-width: 1100px) {
  h2 {
    font-size: 40px;
    font-size: 2.5rem;
    margin-bottom: 0;
  }
}

h3 {
  font-family: "Sarabun";
  font-size: 40px;
  font-size: 2.5rem;
  line-height: normal;
  color: #525252;
}
@media (max-width: 767px) {
  h3 {
    font-size: 24px;
    font-size: 1.5rem;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  h3 {
    font-size: 28px;
    font-size: 1.75rem;
  }
}
@media (min-width: 800px) and (max-width: 1100px) {
  h3 {
    font-size: 30px;
    font-size: 1.875rem;
  }
}

h4 {
  font-family: "Sarabun";
  font-size: 25px;
  font-size: 1.5625rem;
  color: #525252;
  line-height: 24px;
  line-height: 1.5rem;
  margin-bottom: 10px;
  font-weight: 700;
}
@media (max-width: 767px) {
  h4 {
    font-size: 21px;
    font-size: 1.3125rem;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  h4 {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}

h5 {
  font-family: "Sarabun";
  font-size: 26px;
  font-size: 1.625rem;
  line-height: normal;
}
@media (max-width: 767px) {
  h5 {
    font-size: 22px;
    font-size: 1.375rem;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  h5 {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

p {
  font-family: "Sarabun";
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 30px;
  line-height: 1.875rem;
  color: #525252;
}
p strong {
  font-weight: 700;
}
@media (max-width: 767px) {
  p {
    font-size: 17px;
    font-size: 1.0625rem;
    line-height: 22px;
    line-height: 1.375rem;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  p {
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 26px;
    line-height: 1.625rem;
  }
}
@media (min-width: 800px) and (max-width: 1100px) {
  p {
    font-size: 19px;
    font-size: 1.1875rem;
  }
}

*:focus {
  outline: none;
}

@media (min-width: 850px) {
  .navbar-toggler {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1365px) {
  .navbar-toggler {
    display: none !important;
  }
}
.container {
  max-width: 1344px;
  width: 100%;
  margin: 0 auto;
}

.row {
  margin: 0px;
}

.headerGrp {
  position: relative;
}
.headerGrp .navbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  padding-top: 15px;
}
@media (max-width: 767px) {
  .headerGrp .navbar {
    position: sticky;
    padding-top: 0;
    background-color: #ededed;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .headerGrp .navbar {
    padding-top: 10px;
  }
}
@media (max-width: 767px) {
  .headerGrp .navbar img {
    max-width: 60%;
  }
}
@media (max-width: 850px) {
  .headerGrp .navbar .navbar-collapse {
    position: sticky;
  }
}
.headerGrp .navbar .navbar-collapse > div {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.headerGrp .navbar .navbar-collapse li {
  display: inline-block;
  position: relative;
}
.headerGrp .navbar .navbar-collapse li a {
  padding: 0 18px;
  color: #525252;
  display: block;
  line-height: 45px;
  line-height: 2.8125rem;
  font-weight: 400;
  font-family: "Sarabun";
  font-size: 18px;
  font-size: 1.125rem;
  position: relative;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .headerGrp .navbar .navbar-collapse li a {
    font-size: 18px;
    font-size: 1.125rem;
    padding: 0 16px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .headerGrp .navbar .navbar-collapse li a {
    padding: 0 6px;
    font-size: 18px;
    font-size: 1.125rem;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .headerGrp .navbar .navbar-collapse li a {
    text-align: center;
    font-size: 16px;
    line-height: 40px;
    line-height: 2.5rem;
  }
}
.headerGrp .navbar .navbar-collapse li a:hover {
  color: #fe5e20;
}
.headerGrp .navbar .navbar-collapse li:last-child a {
  color: #ffffff;
  background: #fe5e20;
  font-family: "Sarabun";
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  border-radius: 5px;
  padding: 0 55px;
  display: inline-block;
  line-height: 60px;
  line-height: 3.75rem;
  border: 1px solid transparent;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-top: 12px;
  transition: all 500ms ease-in-out;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  position: relative;
  border-radius: 6px;
  padding: 0 20px;
  line-height: 50px;
  line-height: 3.125rem;
  font-size: 17px;
  font-size: 1.0625rem;
  font-weight: 500;
  margin-top: 0;
  margin-left: 20px;
}
.headerGrp .navbar .navbar-collapse li:last-child a:hover {
  color: #ffffff;
  background: #3454b2;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .headerGrp .navbar .navbar-collapse li:last-child a {
    font-size: 16px;
    font-size: 1rem;
    line-height: 50px;
    line-height: 3.125rem;
    padding: 0 45px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .headerGrp .navbar .navbar-collapse li:last-child a {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 43px;
    line-height: 2.6875rem;
    padding: 0 38px;
  }
}
@media (max-width: 767px) {
  .headerGrp .navbar .navbar-collapse li:last-child a {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 43px;
    line-height: 2.6875rem;
    padding: 0 37px;
  }
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .headerGrp .navbar .navbar-collapse li:last-child a {
    padding: 0 28px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .headerGrp .navbar .navbar-collapse li:last-child a {
    margin: 0;
    padding: 0 28px;
  }
}
@media (max-width: 767px) {
  .headerGrp .navbar .navbar-collapse li:last-child a {
    line-height: 37px;
    line-height: 2.3125rem;
    padding: 0 25px;
  }
}
@media (max-width: 850px) {
  .headerGrp .navbar .navbar-toggler span {
    display: block;
    background-color: #072138;
    height: 3px;
    width: 25px;
    margin-top: 5px;
    margin-bottom: 5px;
    position: relative;
    left: 0;
    opacity: 1;
    -webkit-transition: all 0.35s ease-out;
    transition: all 0.35s ease-out;
    -webkit-transform-origin: center left;
            transform-origin: center left;
  }
  .headerGrp .navbar .navbar-toggler {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
    border: none;
  }
  .headerGrp .navbar .navbar-toggler:focus {
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    border: none !important;
  }
  .headerGrp .navbar .navbar-toggler span:nth-child(1) {
    margin-top: 0.3em;
  }
  .headerGrp .navbar .navbar-toggler:not(.collapsed) span:nth-child(1) {
    -webkit-transform: translate(15%, -33%) rotate(45deg);
            transform: translate(15%, -33%) rotate(45deg);
  }
  .headerGrp .navbar .navbar-toggler:not(.collapsed) span:nth-child(2) {
    opacity: 0;
  }
  .headerGrp .navbar .navbar-toggler:not(.collapsed) span:nth-child(3) {
    -webkit-transform: translate(15%, 33%) rotate(-45deg);
            transform: translate(15%, 33%) rotate(-45deg);
  }
  .headerGrp .navbar .navbar-toggler span:nth-child(1) {
    -webkit-transform: translate(0%, 0%) rotate(0deg);
            transform: translate(0%, 0%) rotate(0deg);
  }
  .headerGrp .navbar .navbar-toggler span:nth-child(2) {
    opacity: 1;
  }
  .headerGrp .navbar .navbar-toggler span:nth-child(3) {
    -webkit-transform: translate(0%, 0%) rotate(0deg);
            transform: translate(0%, 0%) rotate(0deg);
  }
  .headerGrp .navbar .navbar-collapse {
    position: absolute;
    right: 10px;
    top: 100%;
    z-index: 9999;
    width: 100%;
    background: #ffffff;
    box-shadow: 1px 6px 6px #bdbdbd;
    -webkit-box-shadow: 1px 6px 6px #bdbdbd;
    -moz-box-shadow: 1px 6px 6px #bdbdbd;
    -ms-box-shadow: 1px 6px 6px #bdbdbd;
    -o-box-shadow: 1px 6px 6px #bdbdbd;
    border-radius: 0 0 5px 5px;
    width: calc(100% - 20px);
  }
  .headerGrp .navbar .navbar-collapse li {
    border-top: 1px solid #82bcef;
    width: 100%;
  }
  .headerGrp .navbar .navbar-collapse li:nth-child(1) {
    border-top: 0;
  }
  .headerGrp .navbar .navbar-collapse li:last-child {
    padding: 9px 0;
    text-align: center;
    margin: 0;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .headerGrp .navbar .navbar-collapse {
    width: 75%;
    border-radius: 5px;
  }
}

.siteBanner {
  position: relative;
}
.siteBanner .siteBanner__slider .siteBanner__slider_item {
  position: relative;
}
.siteBanner .siteBanner__slider .siteBanner__slider_item img {
  width: 100%;
  height: auto;
}
.siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
}
@media (min-width: 768px) and (max-width: 999px) {
  .siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay {
    background-color: #ededed;
  }
}
@media (max-width: 767px) {
  .siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay {
    background-color: #ededed;
  }
}
.siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay .container {
  padding: 0 10px;
}
.siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay .bannerOverlay__center {
  width: 100%;
  text-align: left;
  margin-left: auto;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay .bannerOverlay__center {
    margin-top: 70px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay .bannerOverlay__center {
    margin-left: 0;
    text-align: center;
    padding-top: 50px;
  }
}
@media (max-width: 767px) {
  .siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay .bannerOverlay__center {
    text-align: center;
    padding-bottom: 12px;
  }
}
.siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay .bannerOverlay__center h1 {
  color: #393939;
  font-size: 70px;
  font-size: 4.375rem;
  line-height: 72px;
  line-height: 4.5rem;
  font-family: "PT Sans";
  font-weight: 700;
  margin-bottom: 20px;
  letter-spacing: -0.1px;
}
@media (min-width: 1300px) and (max-width: 1450px) {
  .siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay .bannerOverlay__center h1 {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 58px;
    line-height: 3.625rem;
  }
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay .bannerOverlay__center h1 {
    font-size: 50px;
    font-size: 3.125rem;
    line-height: 50px;
    line-height: 3.125rem;
    margin-bottom: 2px;
  }
  .siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay .bannerOverlay__center h1 br {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay .bannerOverlay__center h1 {
    font-size: 45px;
    font-size: 2.8125rem;
    line-height: 53px;
    line-height: 3.3125rem;
    margin-bottom: 0px;
  }
  .siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay .bannerOverlay__center h1 br {
    display: none;
  }
}
@media (max-width: 767px) {
  .siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay .bannerOverlay__center h1 {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 27px;
    line-height: 1.6875rem;
    margin-bottom: 10px;
  }
}
.siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay .bannerOverlay__center P {
  color: #525252;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 27px;
  line-height: 1.6875rem;
  font-family: "Sarabun";
  font-weight: 500;
  letter-spacing: -0.1px;
  margin-bottom: 10px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay .bannerOverlay__center P {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 29px;
    line-height: 1.8125rem;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay .bannerOverlay__center P {
    font-size: 22px;
    font-size: 1.375rem;
    line-height: 30px;
    line-height: 1.875rem;
    padding: 10px 0px;
  }
  .siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay .bannerOverlay__center P br {
    display: none;
  }
}
@media (max-width: 767px) {
  .siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay .bannerOverlay__center P {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 19px;
    line-height: 1.1875rem;
    letter-spacing: 0;
    margin-bottom: 0px;
  }
  .siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay .bannerOverlay__center P br {
    display: none;
  }
}
.siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay .bannerOverlay__center a.rmbttn {
  color: #ffffff;
  background: #3454b2;
  font-family: "Sarabun";
  font-weight: 700;
  font-size: 18px;
  font-size: 1.125rem;
  border-radius: 5px;
  padding: 0 55px;
  display: inline-block;
  line-height: 60px;
  line-height: 3.75rem;
  border: 1px solid transparent;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-top: 12px;
  transition: all 500ms ease-in-out;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -ms-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  position: relative;
  margin-top: 16px;
}
.siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay .bannerOverlay__center a.rmbttn:hover {
  color: #ffffff;
  background: #fe5e20;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay .bannerOverlay__center a.rmbttn {
    font-size: 16px;
    font-size: 1rem;
    line-height: 50px;
    line-height: 3.125rem;
    padding: 0 45px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay .bannerOverlay__center a.rmbttn {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 43px;
    line-height: 2.6875rem;
    padding: 0 38px;
  }
}
@media (max-width: 767px) {
  .siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay .bannerOverlay__center a.rmbttn {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 43px;
    line-height: 2.6875rem;
    padding: 0 37px;
  }
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay .bannerOverlay__center a.rmbttn {
    padding: 0 45px;
    font-size: 18px;
    font-size: 1.125rem;
    line-height: 55px;
    line-height: 3.4375rem;
    margin-top: 12px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay .bannerOverlay__center a.rmbttn {
    margin-top: 5px;
  }
}
@media (max-width: 767px) {
  .siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay .bannerOverlay__center a.rmbttn {
    margin-top: 15px;
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 35px;
    line-height: 2.1875rem;
    padding: 0 24px;
  }
}
.siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay.inner_bannerOverlay__center .bannerOverlay__center {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay.inner_bannerOverlay__center .bannerOverlay__center {
    margin-top: 75px;
  }
}
.siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay.inner_bannerOverlay__center .bannerOverlay__center h1 {
  color: #393939;
  font-size: 70px;
  font-size: 4.375rem;
  line-height: 72px;
  line-height: 4.5rem;
  font-family: "PT Sans";
  font-weight: 700;
  margin-bottom: 20px;
  letter-spacing: -0.1px;
}
@media (min-width: 1300px) and (max-width: 1450px) {
  .siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay.inner_bannerOverlay__center .bannerOverlay__center h1 {
    font-size: 60px;
    font-size: 3.75rem;
    line-height: 68px;
    line-height: 4.25rem;
  }
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay.inner_bannerOverlay__center .bannerOverlay__center h1 {
    font-size: 55px;
    font-size: 3.4375rem;
    line-height: 50px;
    line-height: 3.125rem;
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay.inner_bannerOverlay__center .bannerOverlay__center h1 {
    font-size: 58px;
    font-size: 3.625rem;
    line-height: 53px;
    line-height: 3.3125rem;
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay.inner_bannerOverlay__center .bannerOverlay__center h1 {
    font-size: 28px;
    font-size: 1.75rem;
    line-height: 29px;
    line-height: 1.8125rem;
    margin-bottom: 10px;
  }
  .siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay.inner_bannerOverlay__center .bannerOverlay__center h1 br {
    display: none;
  }
}
.siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay.inner_bannerOverlay__center .bannerOverlay__center p {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 27px;
  line-height: 1.6875rem;
  color: #393939;
  font-family: "Sarabun";
  font-weight: 500;
  letter-spacing: -0.1px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay.inner_bannerOverlay__center .bannerOverlay__center p {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 30px;
    line-height: 1.875rem;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay.inner_bannerOverlay__center .bannerOverlay__center p {
    font-size: 22px;
    font-size: 1.375rem;
    line-height: 32px;
    line-height: 2rem;
    margin-top: 15px;
  }
}
@media (max-width: 767px) {
  .siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay.inner_bannerOverlay__center .bannerOverlay__center p {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 22px;
    line-height: 1.375rem;
  }
  .siteBanner .siteBanner__slider .siteBanner__slider_item .siteBanner__bannerOverlay.inner_bannerOverlay__center .bannerOverlay__center p br {
    display: none;
  }
}

.siteFooter {
  background-color: #0f0f22;
  text-align: center;
}
.siteFooter .siteFooter__top {
  padding: 70px 0px 0px;
}
@media (min-width: 768px) and (max-width: 999px) {
  .siteFooter .siteFooter__top {
    padding: 65px 0 10px;
  }
}
@media (max-width: 767px) {
  .siteFooter .siteFooter__top {
    padding: 40px 0 0px;
  }
}
.siteFooter .siteFooter__top .footerproductLogos {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.siteFooter .siteFooter__top .footerproductLogos .tms_logo {
  padding-left: 15px;
  margin-top: 20px;
}
.siteFooter .siteFooter__top .footerproductLogos .tms_logo a img {
  max-width: 120px;
  width: 100%;
  height: auto;
}
.siteFooter .siteFooter__top .footer-logo img {
  width: auto;
  max-width: 100%;
  height: auto;
}
@media (min-width: 768px) and (max-width: 999px) {
  .siteFooter .siteFooter__top .footer-logo img {
    max-width: 22%;
  }
}
@media (max-width: 767px) {
  .siteFooter .siteFooter__top .footer-logo img {
    max-width: 30%;
  }
}
.siteFooter .siteFooter__top .contact_info {
  margin-top: 12px;
}
.siteFooter .siteFooter__top .contact_info a {
  color: #ffffff;
  font-size: 18px;
  font-family: "Sarabun";
  font-weight: 400;
}
.siteFooter .siteFooter__top .footer_navber {
  margin-top: 38px;
  margin-bottom: 55px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .siteFooter .siteFooter__top .footer_navber {
    margin-top: 50px;
    margin-bottom: 35px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .siteFooter .siteFooter__top .footer_navber {
    margin-top: 35px;
    margin-bottom: 15px;
  }
}
@media (max-width: 767px) {
  .siteFooter .siteFooter__top .footer_navber {
    margin-top: 20px;
    margin-bottom: 15px;
  }
}
.siteFooter .siteFooter__top .footer_navber li {
  display: inline-block;
  padding: 0 25px;
}
@media (min-width: 768px) and (max-width: 999px) {
  .siteFooter .siteFooter__top .footer_navber li {
    padding: 0 12px;
  }
}
@media (max-width: 767px) {
  .siteFooter .siteFooter__top .footer_navber li {
    padding: 4px 10px;
  }
}
.siteFooter .siteFooter__top .footer_navber li a {
  font-family: "Sarabun";
  font-weight: 300;
  font-size: 21px;
  font-size: 1.3125rem;
  color: #ffffff;
}
@media (min-width: 768px) and (max-width: 999px) {
  .siteFooter .siteFooter__top .footer_navber li a {
    font-size: 17px;
    font-size: 1.0625rem;
  }
}
@media (max-width: 767px) {
  .siteFooter .siteFooter__top .footer_navber li a {
    font-size: 15px;
    font-size: 0.9375rem;
  }
}
.siteFooter .siteFooter__top .footer_navber li a:hover {
  color: #fe5e20;
}
.siteFooter hr {
  color: #ffffff;
}
.siteFooter .siteFooter__copytxt {
  padding-bottom: 14px;
  padding-top: 15px;
}
@media (min-width: 1000px) and (max-width: 1299px) {
  .siteFooter .siteFooter__copytxt {
    padding-bottom: 10px;
    padding-top: 42px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .siteFooter .siteFooter__copytxt {
    padding-bottom: 30px;
    padding-top: 19px;
  }
}
@media (max-width: 767px) {
  .siteFooter .siteFooter__copytxt {
    padding-top: 0;
    padding-bottom: 20px;
  }
}
.siteFooter .siteFooter__copytxt p {
  color: #ffffff;
  font-size: 16px;
}

.loader__section {
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
}
.loader__section img {
  position: absolute;
  z-index: 999;
  left: calc(50% - 100px);
  top: calc(50% - 100px);
  max-width: 200px;
}